import {
  required,
  email,
  createI18nMessage,
  sameAs,
  maxLength,
} from '@vuelidate/validators'
import {
  getPayloadDate,
  dateValidator,
  phoneValidator,
} from '@scayle/storefront-nuxt'
import type {
  ValidationRuleWithoutParams,
  ValidationRuleWithParams,
  ValidatorFn,
} from '@vuelidate/core'

const isValidDate = (date: Date) => new Date(date).toString() !== 'Invalid Date'

// This needs to be aligned with CO frontend configuration in Panel
// Validates that a password has at least 8 characters, a special, an uppercase
const validatePassword = (value: string): boolean => {
  const pattern =
    /^(?=.*[!"§€/()^°><|:.,=;?@#$%^&*\-_])(?=.*[a-z])(?=.*[A-Z]).{8,70}$/.test(
      value,
    )
  return pattern && !value.includes(' ')
}

const validateDate = (value: string): boolean => dateValidator(value)

const validatePhone = (value: string): boolean => phoneValidator(value)

const validateFutureDate = (value: string): boolean => {
  const formattedDate = getPayloadDate(value)
  if (!formattedDate) {
    return true
  }
  const date = new Date(formattedDate)
  if (!isValidDate(date)) {
    return true
  }
  const now = new Date()
  return date.getTime() < now.getTime()
}

const validateName = (value: string | undefined): boolean => {
  if (!value) {
    return true
  }
  const hasValidUnicodeLetters = /^[\p{Letter}\s'-.]+$/u.test(value)
  const hasPunctuationStreak = /['-.]{2,}/.test(value)
  const hasValidBoundaries = !/^['-.].*|['.-]$/.test(value)
  return hasValidUnicodeLetters && hasValidBoundaries && !hasPunctuationStreak
}

const validateZipCode = (value: string): boolean => {
  // List of known regex patterns for different countries
  const zipCodePatterns: RegExp[] = [
    /^\d{5}$/, // 5 digits (e.g., 12345)
    /^\d{4}$/, // 4 digits (e.g., 1234)
    /^\d{4}\s?[A-Z]{2}$/, // 4 digits followed by 2 uppercase letters with optional space (e.g., 1234 AB)
    /^\d{2}-\d{3}$/, // 2 digits, a hyphen, followed by 3 digits (e.g., 12-345)
  ]

  return zipCodePatterns.some((pattern) => pattern.test(value))
}

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp()

  const withI18nMessage = createI18nMessage({
    t: $i18n.t.bind($i18n),
    messagePath: ({ $validator }) => `validation.${useSnake($validator)}`,
    messageParams: ({ field, max, otherName, property, ...params }) => ({
      ...params,
      property,
      field: $i18n.t(`form_fields.${useSnake(field || property)}`),
      max,
      otherField: otherName,
    }),
  })

  const rule = {
    required: withI18nMessage(required),
    email: withI18nMessage(email),
    date: withI18nMessage(validateDate),
    phone: withI18nMessage(validatePhone),
    password: withI18nMessage(validatePassword),
    futureDate: withI18nMessage(validateFutureDate),
    name: withI18nMessage(validateName),
    sameAs: withI18nMessage(sameAs, { withArguments: true }),
    maxLength: withI18nMessage(maxLength, { withArguments: true }),
    zipCode: withI18nMessage(validateZipCode),
  }

  return { provide: { validation: { rule } } }
})

export type ValidationPlugin = {
  rule: {
    required: ValidationRuleWithoutParams<any>
    email: ValidationRuleWithoutParams<any>
    date: ValidatorFn<string>
    phone: ValidatorFn<string>
    password: ValidatorFn<string>
    futureDate: ValidatorFn<string>
    name: ValidatorFn<string | undefined>
    sameAs: <E = unknown>(
      equalTo: E | Ref<E>,
      otherName?: string,
    ) => ValidationRuleWithParams<{
      equalTo: E
      otherName: string
    }>
    maxLength: (max: number | Ref<number>) => ValidationRuleWithParams<{
      max: number
    }>
    zipCode: (value: string) => boolean
  }
}
