import {
  getFirstAttributeValue,
  type Product,
  type Variant,
} from '@scayle/storefront-nuxt'

export const getFrameSizesForVariant = (variant: Variant) => {
  const attributes = ['lensWidth', 'bridgeWidth', 'templeLength']
  const sizes: string[] = []
  const hasUnit = (val: string) => '' + parseInt(val, 10) !== val

  attributes.forEach((attribute) => {
    const entry = getFirstAttributeValue(variant.attributes, attribute)
    if (entry && entry.label && (hasUnit(entry.label) || +entry.label)) {
      sizes.push(hasUnit(entry.label) ? entry.label : entry.label + ' mm')
    }
  })

  return sizes.filter(Boolean).join(' / ')
}

export const getColors = (product: Product | Ref<Product | null>) => {
  const p = unref(product)
  const colors = [
    getFirstAttributeValue(p?.attributes ?? {}, 'frameColor'),
    getFirstAttributeValue(p?.attributes ?? {}, 'lensBaseColor'),
  ]

  return colors
    .filter((color) => color)
    .map((color) => color?.label)
    .join(' / ')
}
