const useHearingTestEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackHearingTestCompleted: (message: string) => {
      track(
        'FielmannBasic_ApplicationNotification',
        {
          meta: {
            applicationId: 'Fielmann Hearing Test',
          },
          error: { notification: message },
        },
        {
          overridePageType: 'other',
        },
      )
    },
    trackHearingTestResultsUploaded: (message: string) => {
      track(
        'FielmannBasic_ApplicationNotification',
        {
          meta: {
            applicationId: 'Fielmann Hearing Test',
          },
          error: { notification: message },
        },
        {
          overridePageType: 'other',
        },
      )
    },
  }
}

export default useHearingTestEvents
