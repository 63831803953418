import { Action } from '~/plugins/toast'

export const useToast = () => {
  const { $alert } = useNuxtApp()
  const trackingEventsPromise = useTrackingEvents()

  const alert = (message: string, type: Action, to?: string) => {
    $alert.show(message, type, to)
    const trackeable = [Action.error, Action.warning]
    if (trackeable.includes(type)) {
      trackingEventsPromise.then(({ trackNotification }) =>
        trackNotification(message),
      )
    }
  }

  return { alert }
}
