import { objectHash } from 'ohash'
import { removeEmptyAndSort } from './object'

export const getTtl = (
  duration: string = process.env?.BAPI_CACHE_TIME ?? '04:00',
): number => {
  const durationHours = parseInt(duration.split(':')?.[0] ?? '04', 10)
  const durationMinutes = parseInt(duration.split(':')?.[1] ?? '00', 10)
  const time = Date.now()
  const until = new Date()
  until.setHours(durationHours)
  until.setMinutes(durationMinutes)
  until.setSeconds(0)
  let ttl = (until.getTime() - time) / 1000
  if (ttl < 0) {
    ttl = ttl + 60 * 60 * 24
  }
  return ttl
}

export const getCacheKey = (prefix: string, suffix: string = ''): string => {
  return `${prefix}:${suffix}`
}

export const generateObjectHash = (target: object): string => {
  return objectHash(removeEmptyAndSort(target), { unorderedObjects: true })
}

export type DeviceType = 'mobile' | 'tablet' | 'desktop'

