export enum TRACKING_GROUP {
  STATISTICS = 'C0002:',
  MARKETING = 'C0004:',
}

export const OPTANON_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed'
export const OPTANON_CONSENT_COOKIE_NAME = 'OptanonConsent'

export const deserializeOptanonConsent = (
  OptanonConsent: string,
): Record<string, string> =>
  OptanonConsent?.split('&').reduce((acc, curr) => {
    const [key, value] = curr.split('=')
    // @ts-expect-error
    acc[key] = value
    return acc
  }, {}) || {}

export const isTrackingAllowed = (
  optanonConsent: Record<string, string>,
  group: TRACKING_GROUP,
) => {
  const groups = optanonConsent?.groups?.split(',') || []
  const statisticsGroup = groups.find((statGroup) =>
    statGroup.startsWith(group),
  )
  return statisticsGroup?.split(':')[1] === '1'
}
