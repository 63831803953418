/* eslint sonarjs/cognitive-complexity: 1 */

import type { NavigateToOptions } from '#app/composables/router'
import type { RouteLocationRaw } from '#vue-router'
import {
  getFirstAttributeValue,
  slugify,
  type BrandOrCategorySuggestion,
  type Category,
  type CategorySearchSuggestion,
  type Product,
  type ProductSuggestion,
} from '@scayle/storefront-nuxt'
import { getProductDetailRoute } from '../utils/route'

export default () => {
  const { $fimLocalePath } = useNuxtApp()

  const localizedNavigateTo = async (
    route: RouteLocationRaw,
    options?: NavigateToOptions,
  ) => {
    const routePath = $fimLocalePath(route)
    return await navigateTo(routePath, options)
  }

  const getOrderProductDetailRoute = (
    product: OrderProduct,
    id?: number,
  ): RouteLocationRaw => {
    const name = product.attributes.name.label
    return $fimLocalePath({
      name: 'p-slug',
      params: {
        slug: `${slugify(name)}-${id || product.id}`,
      },
    })
  }

  const getProductDetailPath = (product: Product, id?: number) => {
    const name = getFirstAttributeValue(product.attributes, 'name')?.label
    return $fimLocalePath(`/p/${slugify(name)}-${id || product.id}`)
  }

  const getSearchRoute = (term: string): RouteLocationRaw => {
    return $fimLocalePath({
      name: 'search',
      query: { term },
    })
  }

  const buildCategorySuggestionRoute = ({
    categorySuggestion,
  }: CategorySearchSuggestion): RouteLocationRaw => {
    const { category, filters } = categorySuggestion
    return {
      path: buildCategoryPath(category),
      query: buildFiltersQuery(filters),
    }
  }

  const buildCategoryPath = ({
    id,
    path,
  }: Category | { id: number; path: string }): string => {
    return $fimLocalePath(path)
  }

  const getSearchSuggestionPath = (
    suggestion: ProductSuggestion | BrandOrCategorySuggestion,
  ) => {
    if (!suggestion) {
      return
    }

    if ('product' in suggestion) {
      return getProductDetailPath(suggestion.product)
    }

    const { category, brand } = suggestion
    const route =
      category && brand
        ? `${getCategoryPath(category)}?brand=${brand?.id}`
        : getCategoryPath(category)

    return route && $fimLocalePath(route)
  }

  const getOrderDetailsRoute = (id: number): RouteLocationRaw => {
    return $fimLocalePath({
      name: routeList.orderDetail.name,
      params: { id },
    })
  }

  return {
    getOrderDetailsRoute,
    getOrderProductDetailRoute,
    getProductDetailRoute,
    getSearchRoute,
    getSearchSuggestionPath,
    localizedNavigateTo,
    buildCategorySuggestionRoute,
    buildCategoryPath,
  }
}
