import {
  getFirstAttributeValue,
  type Price,
  type Product,
  type Variant,
  type Value,
} from '@scayle/storefront-nuxt'
import { sumUpPrice } from './price'

const cheapestGlassProductReducer = (prev: Product, curr: Product) => {
  const prevVariant = prev?.variants && prev?.variants[0]
  const currVariant = curr?.variants && curr?.variants[0]
  return prevVariant &&
    currVariant &&
    prevVariant.price.withTax < currVariant.price.withTax
    ? prev
    : curr
}

export const getCheapestGlassVariant = (lensesProducts: Variant[]): Variant => {
  return lensesProducts.reduce((prev, curr) => {
    return prev.price.withTax < curr.price.withTax ? prev : curr
  }, lensesProducts?.[0])
}

export const getCheapestGlassProduct = (lensesProducts: Product[]): Product => {
  return lensesProducts.reduce(cheapestGlassProductReducer, lensesProducts?.[0])
}

export const combinePricesWithCheapestLens = (
  price: Price,
  lensesProducts: Variant[],
) => {
  return sumUpPrice([
    price,
    getCheapestGlassVariant(lensesProducts)?.price ?? {
      ...price,
      appliedReductions: [],
      withTax: 0,
      withoutTax: 0,
    },
  ])
}

const isMonoLensCategory = (categoryValue: Value) =>
  ['ski goggles', 'mono lens'].some((monoLensCategoryLabel) =>
    categoryValue.label?.toLowerCase().includes(monoLensCategoryLabel),
  )

// https://aboutyou.atlassian.net/browse/SCFIM-1892
export const isMonoLensGlassProduct = (product: Product) => {
  if (
    getFirstAttributeValue(product.attributes, 'shape')?.value === 'monoscheibe'
  ) {
    return true
  }
  const categoryValues = product.attributes?.category?.values
  if (Array.isArray(categoryValues)) {
    return categoryValues.some((category) => isMonoLensCategory(category))
  }
  return categoryValues ? isMonoLensCategory(categoryValues) : false
}
