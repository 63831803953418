import type { NavigationLink } from '~/rpcMethods/navigation'

const filterTree = (
  items: NavigationLink[],
  filterFn: (item: NavigationLink) => boolean | undefined,
): NavigationLink[] => {
  return items.filter(filterFn).map((item) => ({
    ...item,
    children: filterTree(item.children, filterFn),
  }))
}

export const useFimNavigationTree = () => {
  const currentShop = useCurrentShop()
  return useAsyncRpc(
    'getFimNavigationTree',
    {
      cmsBaseFolder: getLocalizedBaseFolder(currentShop.value, 'content'),
    },
    {
      dedupe: 'cancel',
      immediate: true,
      server: true,
      watch: [currentShop],
      transform: (input) =>
        filterTree(input || [], (item) => {
          const now = Date.now()
          const visibleFrom = Date.parse(item.visibleFrom || '') || now
          const visibleTo = Date.parse(item.visibleTo || '') || now

          return visibleFrom <= now && visibleTo >= now
        }),
    },
  )
}
