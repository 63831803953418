export default defineNuxtRouteMiddleware((to, from) => {
  const { setPageState } = usePageState()

  const prevPageType = from.meta?.pageType
  const currentPageType = to.meta?.pageType

  if (currentPageType) {
    setPageState('pageType', currentPageType as PageType)
  }

  if (prevPageType === 'category') {
    setPageState('lastCategoryRoute', {
      fullPath: from.fullPath,
      isRopoPlp: !!from.meta.isRopoPlp,
    })
  }

  // always reset these, they get set in appropriate component if needed
  setPageState('plpType', '')
  setPageState('productCategory', '')
})
