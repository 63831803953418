// Docs: https://aboutyou.atlassian.net/wiki/spaces/AYC/pages/1247117325/01+-+Solution+Design+Appointments
export enum ProductCategoryDto {
  CL = 'CL',
  HA = 'HA',
  GL = 'GL',
  AC = 'AC',
}

export enum ServiceCategoryDto {
  CL_CF = 'CL_CF',
  CL_C = 'CL_C',
  HA_HT = 'HA_HT',
  HA_FCA = 'HA_FCA',
  HA_SE = 'HA_SE',
  HA_NK = 'HA_NK',
  HA_GS = 'HA_GS',
  GL_CA = 'GL_CA',
  GL_PU = 'GL_PU',
  GL_SE = 'GL_SE',
  GL_FS = 'GL_FS',
  GL_VS = 'GL_VS',
  GL_AC = 'GL_AC',
  GL_CR = 'GL_CR',
  GL_CA_DE = 'GL_CA_DE',
  GL_CA_IT = 'GL_CA_IT',
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type SpecialDayDto = {
  date: string
  periods: {
    opens: string
    closes: string
  }[]
}

export type ServiceDto = {
  id: string
  productCategory: ProductCategoryDto
  serviceCategory?: ServiceCategoryDto
  serviceName?: ServiceCategoryDto
  batchBooking: boolean
  name: string
  description: string
  durationInMinutes: number
  price: number
  online: boolean
  listedOnFrontend: boolean
  onlyGuestBooking: boolean
  enableServiceHint: boolean
  serviceHint: string | null
}

type RegularPeriodDto = {
  day: DayOfWeek
  periods: {
    opens: string
    closes: string
  }[]
}

export type BranchDto = {
  id: string
  name: string
  street: string
  zipCode: string
  city: string
  phoneNumber: string
  openingHours: {
    regular: RegularPeriodDto[]
    special: SpecialDayDto[]
  }
  localeBCP47: string
  appointmentGuidelines: string | null
  supportedLanguages: string[]
}

export type AppointmentFormatted = {
  date: string
  time: string
  dateTime: string
}

export type Appointment = {
  cancelable: boolean
  cancelled: boolean
  inPast: boolean
  id: string
  cancellationToken: string
  service: ServiceDto
  dateTime: string
  branch: BranchDto
  bookedByGuestAccount: boolean
  orderNumber: string | null
  formatted?: AppointmentFormatted
}
