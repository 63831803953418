<template>
  <LazyIconFielmannLogoCZ
    v-if="country === 'CZ'"
    class="w-20 pt-1 header:w-28 header:p-1.5 header:pl-0 header:pt-3"
  />
  <LazyIconFielmannLogoPL
    v-else-if="country === 'PL'"
    class="w-20 pt-1 header:w-28 header:p-1.5 header:pl-0 header:pt-3"
  />
  <LazyIconFielmannLogo
    v-else
    class="w-20 header:w-28 header:p-1.5 header:pl-0"
  />
</template>

<script setup lang="ts">
const $currentShop = useCurrentShop()
const country = getIsoCountry($currentShop.value.locale)
</script>
