import type { NavigationLink } from '~/rpcMethods/navigation'
import { useDataDogTest } from '~/composables/useDataDogTest'

const uiState = reactive({
  sideNavigationOpen: false,
  flyoutMenuOpen: false,
  flyoutMenuLink: {
    label: '',
    href: '',
    slug: '',
    assets: {},
    type: 'internal',
    visibleFrom: null,
    visibleTo: null,
    children: [],
  } as NavigationLink,
  searchFlyout: false,
  mobileSearchIsActive: false,
  basketFlyout: false,
  selectedSideNavigationEntries: [] as NavigationLink[],
  modalOpen: false,
  dialogOpen: false,
  reorderConfirmationDialogOpen: false,
  overlayOpen: false,
  rxConfiguratorOverlayOpen: false,
  rxEditPrescriptionOverlayId: '',
  ropoOverlayOpen: false,
  ropoProductName: '',
  languageSwitcherOverlayOpen: false,
  basketOverlayOpen: false,
  fitMixOpen: false,
})
export default () => {
  const { isDataDogTest } = useDataDogTest()
  const isSideNavigationOpen = computed(() => uiState.sideNavigationOpen)
  const closeSideNavigation = () => {
    uiState.sideNavigationOpen = false
  }
  const openSideNavigation = () => {
    uiState.sideNavigationOpen = true
  }

  const isBasketOverlayOpen = computed(() => uiState.basketOverlayOpen)
  const closeBasketOverlay = () => {
    uiState.basketOverlayOpen = false
    uiState.overlayOpen = false
  }
  const openBasketOverlay = () => {
    uiState.basketOverlayOpen = true
  }

  const selectSideNavigationEntry = (link: NavigationLink, level: number) => {
    uiState.selectedSideNavigationEntries[level] = link
    if (link.children?.length === 0) {
      uiState.sideNavigationOpen = false
    }
  }

  const resetSideNavigationEntry = (index?: number) => {
    if (index && index > -1) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete uiState.selectedSideNavigationEntries[index]
    } else {
      uiState.selectedSideNavigationEntries = []
    }
  }

  const isFlyoutMenuOpen = computed(() => uiState.flyoutMenuOpen)
  const flyoutMenuLink = computed(() => uiState.flyoutMenuLink)

  const closeFlyoutMenu = (event: MouseEvent, force: boolean = false) => {
    const relatedTarget = event.relatedTarget as Element

    if (
      ![
        'flyout-menu',
        'flyout-menu-items-container',
        'flyout-overscroll-container',
        'flyout-menu-items-container-content',
      ].includes(relatedTarget?.id) ||
      force
    ) {
      uiState.flyoutMenuOpen = false
      uiState.flyoutMenuLink = {
        label: '',
        href: '',
        slug: '',
        assets: {},
        type: 'internal',
        visibleFrom: null,
        visibleTo: null,
        children: [],
      }
    }
  }
  const openFlyoutMenu = (link: NavigationLink) => {
    if (link?.children?.length === 0) {
      uiState.flyoutMenuOpen = false
      return
    }

    uiState.flyoutMenuOpen = true
    uiState.flyoutMenuLink = { ...link }
  }

  const isSearchFlyoutOpen = computed(() => uiState.searchFlyout)

  const openSearchFlyout = () => {
    uiState.searchFlyout = true
  }

  const closeSearchFlyout = () => {
    uiState.searchFlyout = false
  }

  const openFitMix = () => {
    uiState.fitMixOpen = true
  }

  const closeFitMix = () => {
    uiState.fitMixOpen = false
  }

  const openBasketFlyout = () => {
    uiState.basketFlyout = true
  }

  const closeBasketFlyout = () => {
    uiState.basketFlyout = false
  }

  const mobileSearchIsActive = computed(() => uiState.mobileSearchIsActive)

  const setMobileSearchIsActive = (value: boolean) =>
    (uiState.mobileSearchIsActive = value)

  const setModalState = (isOpen: boolean) => {
    uiState.modalOpen = isOpen
  }

  const setDialogState = (isOpen: boolean) => {
    uiState.dialogOpen = isOpen
  }

  const setFlyoutState = (isOpen: boolean) => {
    uiState.searchFlyout = isOpen
  }

  const setOverlayState = (isOpen: boolean) => {
    uiState.overlayOpen = isOpen
  }

  const openRxConfiguratorOverlay = () => {
    uiState.rxConfiguratorOverlayOpen = true
  }

  const closeRxConfiguratorOverlay = () => {
    uiState.rxConfiguratorOverlayOpen = false
    uiState.overlayOpen = false
  }

  const openRxEditPrescriptionOverlay = (id: string) => {
    uiState.rxEditPrescriptionOverlayId = id
  }
  const closeRxEditPrescriptionOverlay = () => {
    uiState.rxEditPrescriptionOverlayId = ''
  }

  const openRopoOverlay = (productName: string) => {
    uiState.ropoProductName = productName
    uiState.ropoOverlayOpen = true
  }
  const closeRopoOverlay = () => {
    uiState.ropoProductName = ''
    uiState.ropoOverlayOpen = false
  }

  const isRopoOverlayOpen = computed(() => uiState.ropoOverlayOpen)
  const ropoProductName = computed(() => uiState.ropoProductName)

  const isLanguageSwitcherOverlayOpen = computed(
    () => uiState.languageSwitcherOverlayOpen,
  )
  const openLanguageSwitcherOverlay = () => {
    if (isDataDogTest.value) {
      return
    }

    uiState.languageSwitcherOverlayOpen = true
  }
  const closeLanguageSwitcherOverlay = () => {
    uiState.languageSwitcherOverlayOpen = false
  }

  return {
    isSideNavigationOpen,
    closeSideNavigation,
    openSideNavigation,
    selectSideNavigationEntry,
    resetSideNavigationEntry,
    isFlyoutMenuOpen,
    flyoutMenuLink,
    closeFlyoutMenu,
    openFlyoutMenu,
    isSearchFlyoutOpen,
    closeSearchFlyout,
    openSearchFlyout,
    mobileSearchIsActive,
    setMobileSearchIsActive,
    selectedSideNavigationEntries: computed(
      () => uiState.selectedSideNavigationEntries,
    ),
    isBasketFlyoutOpen: computed(() => uiState.basketFlyout),
    openBasketFlyout,
    closeBasketFlyout,
    isModalOpen: computed(() => uiState.modalOpen),
    setModalState,
    isDialogOpen: computed(() => uiState.dialogOpen),
    setDialogState,
    setFlyoutState,
    setOverlayState,
    isOverlayOpen: computed(() => uiState.overlayOpen),
    openRxConfiguratorOverlay,
    closeRxConfiguratorOverlay,
    isRxConfiguratorOverlayOpen: computed(
      () => uiState.rxConfiguratorOverlayOpen,
    ),
    openRxEditPrescriptionOverlay,
    closeRxEditPrescriptionOverlay,
    rxEditPrescriptionOverlayId: computed(
      () => uiState.rxEditPrescriptionOverlayId,
    ),
    isRxEditPrescriptionOverlayOpen: computed(
      () => !!uiState.rxEditPrescriptionOverlayId,
    ),
    openFitMix,
    closeFitMix,
    isFitMixOpen: computed(() => uiState.fitMixOpen),
    openRopoOverlay,
    closeRopoOverlay,
    isRopoOverlayOpen,
    ropoProductName,
    isBasketOverlayOpen,
    closeBasketOverlay,
    openBasketOverlay,
    isLanguageSwitcherOverlayOpen,
    openLanguageSwitcherOverlay,
    closeLanguageSwitcherOverlay,
  }
}
