import type { Product } from '@scayle/storefront-nuxt'

// import { PDPComponentType } from '../composables/pdp/pdpTypes'
import { PDPComponentType } from '../types/pdp'
import { getAttributesCategory } from './attribute'

const checkCategories = (
  product: Product | null | undefined,
  keys: string[],
) => {
  const categories = getAttributesCategory(product)?.values

  if (product && !categories) {
    console.warn(
      `[checkCategories] categories not available on product: ${product.id}`,
    )
  }

  if (!Array.isArray(categories)) {
    return false
  }

  return categories.some((value) =>
    keys.some((key) => value.label.includes(key)),
  )
}

export const isGlasses = (product: Product | null | undefined): boolean => {
  const hasFrameColor = !!product?.attributes?.frameColor

  return (
    checkCategories(product, [
      'Master|Eyewear|Sunglasses',
      'Master|Eyewear|Ski Googles',
    ]) ||
    // Fallback
    (hasFrameColor &&
      !checkCategories(product, [
        'Master|Eyewear|Frames',
        'Master|Eyewear|Ready Made Glasses',
        'Master|Eyewear|Blue Light Blocking Glasses',
        'Master|Eyewear|Reading Aids',
      ]))
  )
}

export const isReadyMadeGlasses = (
  product: Product | null | undefined,
): boolean => {
  return checkCategories(product, [
    'Master|Eyewear|Ready Made Glasses',
    'Master|Eyewear|Blue Light Blocking Glasses',
  ])
}

export const isReadingAidGlasses = (
  product: Product | null | undefined,
): boolean => {
  return checkCategories(product, ['Master|Eyewear|Reading Aids'])
}

export const isCorrectionalGlasses = (
  product: Product | null | undefined,
): boolean => {
  if (isGlasses(product) || isReadyMadeGlasses(product)) {
    return false
  }

  return checkCategories(product, ['Master|Eyewear|Frames'])
}

export const isContactLens = (product: Product | null | undefined): boolean => {
  return (
    checkCategories(product, [
      'Products|Contact Lenses',
      'Master|Kontaktlinsen und Pflegemittel|Contact Lenses',
      'Master|Contact Lenses and Care Products|Contact Lenses',
    ]) || Boolean(product?.attributes?.contactLensType)
  )
}

export const isCareProducts = (
  product: Product | null | undefined,
): boolean => {
  if (isContactLens(product)) {
    return false
  }

  return (
    checkCategories(product, [
      'Products|Care Products',
      'Master|Kontaktlinsen und Pflegemittel|Care Products',
      'Master|Contact Lenses and Care Products|Care Products',
    ]) || Boolean(product?.attributes?.eyeCareProductType)
  )
}

export const isMerch = (product: Product | null | undefined): boolean => {
  return checkCategories(product, [
    'Master|Merchant Products',
    'Products|Merchandise',
  ])
}

export const getPDPComponentType = (
  product: Product | null | undefined,
): PDPComponentType => {
  if (
    isGlasses(product) ||
    isReadyMadeGlasses(product) ||
    isReadingAidGlasses(product)
  ) {
    return PDPComponentType.glasses
  }
  if (isCorrectionalGlasses(product)) {
    return PDPComponentType.correctionalGlasses
  }
  if (isContactLens(product)) {
    return PDPComponentType.contactLens
  }
  if (isCareProducts(product)) {
    return PDPComponentType.care
  }
  if (isMerch(product)) {
    return PDPComponentType.merch
  }

  return PDPComponentType.merch
}
