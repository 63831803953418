/**
 * This enum ideally would not be located here, but when it's placed in a file
 * outside of rpcMethods directory it causes issues during nuxt build since it's
 * imported into rpcMethods/rxConfigurator.ts. Build breaks with
 * "Cannot find module". Issue comes somewhere from the fact that we're using
 * rpcMethods in nuxt.config and they're processed via our register module
 * found in SFC package. TODO: Explore the issue further at some point
 */
export enum RxConfMasterKeys {
  lensTypes = 'rx_lens_types',
  lensQuality = 'rx_lens_quality_index',
  tintingTypes = 'rx_tinting_types',
  freeService = 'rx_services',
}
