<template>
  <button
    class="ghost flex flex-col gap-2 space-y-2"
    :aria-label="$t('global.menu_open')"
    @click.prevent="emit('click')"
    @click.stop="emit('click:stop')"
  >
    <LazyIconFielmannBurger class="size-7" />
  </button>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: 'click' | 'click:stop'): void
}>()
</script>
