<template>
  <div class="overflow-x-hidden bg-red-200">
    <div
      class="mx-auto mt-0 w-full max-w-7xl overflow-x-auto p-4 md:mt-0 md:px-12"
    >
      <div class="my-8 text-3xl font-bold">
        {{ statusCode }} – {{ statusMessage }}
      </div>
      <div v-if="error?.stack" class="my-8 flex">
        <pre v-dompurify-html="error?.stack" class="pr-8 text-sm" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  error: {
    type: Object as PropType<any>,
    default: undefined,
  },
})

const statusCode = computed(
  () => props.error?.status || props.error?.statusCode || '5??',
)
const statusMessage = computed(
  () => props.error?.message || props.error?.statusMessage,
)

onMounted(() => {
  console.error(statusCode.value, statusMessage.value)
})
</script>
