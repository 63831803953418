import type { SbImage, Sbasset } from '../types/storyblok'

type CmsImage = {
  id?: number
  filename: string
  alt: string
  name?: string
  focus?: string
  title?: string
  copyright?: string
  fieldtype?: 'string'
}

type SanitizedImage = {
  src: string
  alt: string
}

type Point = {
  x: number
  y: number
}

const getDimensionsFromUrl = (url: string) => {
  const [, curWidth, curHeight] = /\/(\d+)x(\d+)\//.exec(url) || []
  const [, origWidth, origHeight] = /.*\/(\d+)x(\d+)\//.exec(url) || []
  const origRatio = parseInt(origWidth, 10) / parseInt(origHeight, 10)

  let width = parseInt(curWidth, 10)
  let height = parseInt(curHeight, 10)

  if (width && !height && origRatio) {
    height = width / origRatio
  } else if (!width && height && origRatio) {
    width = height * origRatio
  }

  return { width, height }
}

export function cmsImageResolver(cmsImg: SbImage): SanitizedImage {
  const sanitizedImage: SanitizedImage = reactive({
    src: '',
    alt: '',
  })

  if (cmsImg) {
    sanitizedImage.src =
      cmsImg[useViewport().isLessThan('md') ? 'mobile_image' : 'desktop_image']
        ?.filename || ''
    sanitizedImage.alt =
      cmsImg?.mobile_image?.alt || cmsImg?.desktop_image?.alt || ''
  }
  return sanitizedImage
}

export function getFocusPoint(
  cmsImg: CmsImage | Sbasset | undefined,
): Point | undefined {
  if (!cmsImg?.focus) {
    return undefined
  }

  const { width, height } = getDimensionsFromUrl(cmsImg.filename)
  const [position] = cmsImg.focus?.split(':') ?? ['']
  const [xString, yString] = position?.split('x') ?? ['0x0']
  const x = parseInt(xString, 10)
  const y = parseInt(yString, 10)

  return {
    x: Math.round((x / width) * 100) / 100,
    y: Math.round((y / height) * 100) / 100,
  }
}
