import type { Component } from 'vue'
import type { DocumentNode } from '../types/richtext'
import { useRichTextRenderer } from '../composables/useRichTextRenderer'
import type { RendererOptions } from '~/storyblok/utils/richtext/renderer'

interface RichTextRendererProps {
  document: DocumentNode
  options?: RendererOptions
}

const RichTextRenderer: Component = (props: RichTextRendererProps) => {
  const { renderDocument } = useRichTextRenderer(props.options)
  return renderDocument(props.document)
}

export default RichTextRenderer
