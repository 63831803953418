import type { ProductImage } from '@scayle/storefront-nuxt'

export enum PDPComponentType {
  care = 'cleaning-fluid-pdp',
  contactLens = 'contact-lens-pdp',
  glasses = 'glasses-pdp',
  merch = 'merch-pdp',
  correctionalGlasses = 'correctional-glasses',
}

/**
 * Attributes are shown in order on Checkout
 * attribute-1 will show first, then 2 and 3
 */
export enum DisplayDataAttributes {
  colorAttribute = 'attribute-1',
  sizeAttribute = 'attribute-2',
  deliveryForecastAttribute = 'attribute-3',
}

export type ILensOptionFeature = {
  label: string
  icon?: string
  iconSize?: number
  isSpecial?: boolean
}

export type ILensOption = {
  baseUsp: ILensOptionFeature[]
  onTopUsp: ILensOptionFeature[]
  title: string
  image: ProductImage
  description: string
  isRecommendation: boolean
  price: number
  subtitle: string
}
