
import * as fimRuntime$HKJ2EGqQCm from '/builds/aboutyou/cloud-agency/shop-application/fim-sfa/providers/fim/index.ts'
import * as storyblokRuntime$hJDzuYic62 from '/builds/aboutyou/cloud-agency/shop-application/fim-sfa/providers/storyblok/index.ts'
import * as localRuntime$akDMYHL02W from '/builds/aboutyou/cloud-agency/shop-application/fim-sfa/providers/local/index.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "fim",
  "domains": [
    "a.storyblok.com",
    "img.youtube.com",
    "i.vimeocdn.com"
  ],
  "alias": {
    "/youtube": "https://img.youtube.com",
    "/vimeo": "https://i.vimeocdn.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['fim']: { provider: fimRuntime$HKJ2EGqQCm, defaults: undefined },
  ['storyblok']: { provider: storyblokRuntime$hJDzuYic62, defaults: undefined },
  ['local']: { provider: localRuntime$akDMYHL02W, defaults: {"baseURL":"/"} }
}
        