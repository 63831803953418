export const useStoryblokHelpers = () => {
  const route = useRoute()

  const isInEditorMode = computed(() => getIsInEditorMode(route.query))

  const getContentVersion = () => {
    const environment = process.env.NODE_ENV
    const isAllowedDraft = ['staging', 'integration', 'development']
    if (environment && isAllowedDraft.includes(environment)) {
      return isInEditorMode.value ? 'draft' : 'published'
    }
    return 'published'
  }

  return {
    isInEditorMode,
    getContentVersion,
  }
}
