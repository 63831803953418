<template>
  <header class="sticky top-0 z-50 w-full bg-white" data-testid="main-header">
    <div
      class="flex min-h-[55px] items-center border-b border-neutral-40 py-1.5 shadow-md header:min-h-[63px] header:py-0.5 header:shadow-none"
    >
      <div
        class="mx-auto flex w-full max-w-7xl items-center justify-between px-2 accountMenuMobile:px-5"
      >
        <HamburgerButton
          v-if="isMobile"
          class="header:hidden"
          data-testid="hamburger-button"
          @click="toggleSideNavigation"
        />
        <div class="ml-4 header:mb-2 header:ml-0">
          <Logo />
        </div>
        <div v-if="!hideNavigation" class="ml-4 flex-1 md:mr-4 2xl:ml-28">
          <HeaderNavigation
            v-if="isDesktop"
            class="hidden header:block"
            :navigation-tree="navigationTree"
            :fetching="fetching"
            :flyout-menu-open="isFlyoutMenuOpen"
            @mouseenter:item="openFlyoutMenu"
            @mouseleave="closeFlyoutMenu"
          />
        </div>
        <div
          v-if="!hideNavigation"
          class="flex shrink grow basis-80 items-stretch justify-end gap-3 md:min-w-80"
        >
          <div class="justify-center xl:w-80">
            <HeaderSearch />
          </div>
          <HeaderMainMenu />
        </div>
      </div>
    </div>
    <FlyoutMenu
      v-if="!hideNavigation"
      :is-open="isFlyoutMenuOpen"
      justify="center"
      @mouseleave="closeFlyoutMenu"
    >
      <!--- THE ID's ARE IMPORTANT TO NOT CLOSE FLYOUT WHILE FAST MOVING MOUSE TO ITEMS -->
      <div id="flyout-menu-items-container" class="flex space-x-20">
        <div
          v-if="childlessMenuItems.length > 0"
          id="flyout-menu-items-container-content"
          :key="flyoutMenuLink.slug"
        >
          <Headline tag="span">
            <span class="text-gray-500">
              {{ flyoutMenuLink.label }}
            </span>
          </Headline>
          <TwoColumnList :items="childlessMenuItems" class="mt-4">
            <template #item="{ item }">
              <LazyHeaderLinkOrAnchor
                :item="item"
                :on-link-click="onLinkItemClick"
              />
            </template>
          </TwoColumnList>
        </div>
        <template v-for="link in flyoutMenuLink.children">
          <div
            v-if="link.children.length"
            :id="'flyout-menu-items-container-content-' + link.slug"
            :key="link.slug"
          >
            <Headline tag="span" size="base">
              <span class="text-gray-500">
                {{ link.label }}
              </span>
            </Headline>
            <TwoColumnList :items="link.children" class="mt-2">
              <template #item="{ item }">
                <LazyHeaderLinkOrAnchor
                  :item="item"
                  :on-link-click="onLinkItemClick"
                />
              </template>
            </TwoColumnList>
          </div>
        </template>
      </div>
      <template #teaser>
        <slot name="flyout-teaser" />
      </template>
    </FlyoutMenu>
  </header>
</template>

<script setup lang="ts">
/*


*/

import useUiState from '~/composables/ui/useUiState'
import type { NavigationLink } from '~/rpcMethods/navigation'

defineProps({
  fetching: {
    type: Boolean,
    default: () => false,
  },
  navigationTree: {
    type: Array as PropType<NavigationLink[]>,
    default: () => [],
  },
})
const scope = effectScope()
onScopeDispose(() => scope.stop())
const isDesktop = useState('is-desktop', () => true)
const isMobile = useState('is-mobile', () => true)

const { isFlyoutMenuOpen, closeFlyoutMenu, flyoutMenuLink, openFlyoutMenu } =
  useUiState()

const { toggleSideNavigation } = useSideNavigation()

const route = useRoute()
const { trackMenuItemClick } = await useTrackingEvents()
const { isLessThan, isGreaterOrEquals, breakpoint } = useViewport()
scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch(breakpoint, () => {
    isMobile.value = isLessThan('header')
    isDesktop.value = isGreaterOrEquals('header')
  })
})
const childlessMenuItems = computed(() =>
  flyoutMenuLink.value.children.filter((item) => item.children?.length === 0),
)

const hideNavigation = computed(
  () =>
    route.path === routeList.checkout.path || route.query.showBasket === 'true',
)

const onLinkItemClick = (event: MouseEvent) => {
  closeFlyoutMenu(event, true)
  trackMenuItemClick({ element: event.target as HTMLButtonElement })
}

// const {
//   isFlyoutMenuOpen,
//   closeFlyoutMenu,
//   openFlyoutMenu,
//   openFlyoutMenuForNavigationTree,
// } = useFlyouts()

// const { isSideNavigationOpen, toggleSideNavigation } = useSideNavigation()
</script>
