import type {
  Variant,
  Price as BapiPrice,
  CentAmount,
} from '@scayle/storefront-nuxt'
import type { ConfiguratorState } from '~/composables/useRxConfiguratorState'

const reducePriceProp = (
  rxConfiguration: ConfiguratorState,
  pricePropName: 'withTax' | 'withoutTax',
): CentAmount =>
  Object.values(rxConfiguration).reduce((acc, curVal) => {
    if (curVal && 'variant' in curVal) {
      return acc + curVal.variant.price[pricePropName]
    }
    return acc
  }, 0) as CentAmount

const useRxConfiguratorEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackRxConfigurator: (payload: FimRxConfiguratorPayload) => {
      track('FielmannBasic_Configurator', payload)
    },
    aggregateRxConfigurationPrice: (
      rxConfiguration: ConfiguratorState,
      selectedVariant: Variant,
    ): BapiPrice => {
      return {
        ...selectedVariant.price,
        withTax: reducePriceProp(rxConfiguration, 'withTax'),
        withoutTax: reducePriceProp(rxConfiguration, 'withoutTax'),
      }
    },
  }
}

export default useRxConfiguratorEvents
