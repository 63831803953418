<template>
  <Transition
    v-bind="activeClasses"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    :appear="appear"
    mode="out-in"
  >
    <slot />
  </Transition>
</template>

<script setup lang="ts">
const props = defineProps(transitionsProps)
const { activeClasses } = useTransitions(props.duration)
</script>
