<template>
  <Popover
    class="inline-flex items-center px-2"
    :is-open="isBasketFlyoutOpen"
    data-testid="basket-popover"
    content-classes="shadow-lg top-[49px] header:top-[61px] border-t-0 h-100"
    @mouseenter="openBasketFlyout"
    @mouseleave="closeBasketFlyout"
    @pointerdown.stop="toggleBasketFlyout"
  >
    <template #action>
      <FimLink
        :aria-label="badgeCount ? ariaLabel : $t('navigation.basket')"
        :to="isGreaterOrEquals('lg') ? localePath(routeList.basket) : ''"
        type="loud"
        badge-position="top-2.5"
        :badge="badgeCount || undefined"
        :badge-size="badgeCount ? badgeSize : 1"
        :bold-on-active="boldOnActive"
        data-testid="header-cart"
        class="no-prerender"
        data-tracking-id="navigation_cart"
        data-tracking-label="Cart"
        @click="onLinkClick"
      >
        <LazyIconFielmannCart class="size-7 stroke-[1.5]" />
      </FimLink>
    </template>
    <template #content>
      <ClientOnly>
        <div
          v-if="isGreaterOrEquals('lg')"
          class="flex flex-col gap-3 px-5 pb-5 pt-3"
        >
          <div class="flex justify-between">
            <Headline tag="h4" look="h4">
              {{ $t('basket.heading') }}
            </Headline>
            <FimButton type="link" no-padding @click="closeBasketFlyout">
              <template #icon>
                <LazyIconFielmannClose class="size-6" />
              </template>
            </FimButton>
          </div>
          <div
            v-if="basketItems.length"
            class="overflow-auto"
            :style="`max-height: min(${maxHeight}px,calc(100vh - 12rem))`"
          >
            <article
              v-for="(item, i) in basketItems"
              :key="item.key"
              :ref="i === 0 ? 'articleCard' : `articleCard-${i}`"
            >
              <hr class="w-full bg-gray-500" />
              <HeaderBasketPopoverCard
                is-light-variant
                :basket-item="item"
                :product="item.product"
                :variant="item.variant"
                :name="getFirstAttributeLabel(item.product.attributes, 'brand')"
                :title="getFirstAttributeLabel(item.product.attributes, 'name')"
                :image="
                  getImageFromList(
                    item.product.images,
                    ProductImageType.BUST,
                    'front',
                  ) || undefined
                "
                :price="getItemUnitPrice(item)"
                :quantity="item.quantity"
                :display-data="item.displayData"
                :custom-data="item.customData"
              />
            </article>
          </div>
          <HeaderBasketPopoverActions
            v-if="basketItems.length"
            @click:begin_checkout="trackBeginCheckout(basketItems)"
          />
          <div
            v-if="!basketItems.length"
            class="flex w-full flex-col items-center justify-center py-4"
          >
            <div class="w-2/3">
              <p class="text-center text-sm">
                {{ $t('basket.no_items_info') }}
              </p>
            </div>
          </div>
        </div>
        <div v-else class="flex w-48 p-5">
          <HeaderBasketPopoverActions
            @click:begin_checkout="trackBeginCheckout(basketItems)"
          />
        </div>
      </ClientOnly>
    </template>
  </Popover>
</template>

<script setup lang="ts">
import useUiState from '~/composables/ui/useUiState'
import { useFimBasket } from '~/composables/useFimBasket'
import useCheckoutBasketSync from '~/composables/useCheckoutBasketSync'

defineProps({
  boldOnActive: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
})

const scope = effectScope()
onScopeDispose(() => scope.stop())
const $i18n = useI18n()
const { openBasketFlyout, closeBasketFlyout, isBasketFlyoutOpen } = useUiState()
const articleCard = ref()
const showMaxArticleCount = 4
const { isGreaterOrEquals } = useViewport()
const localePath = useLocalePath()
const { trackMenuIconClick, trackBeginCheckout } = await useTrackingEvents()

const toggleBasketFlyout = () => {
  if (isBasketFlyoutOpen.value) {
    closeBasketFlyout()
  } else {
    openBasketFlyout()
  }
}

const {
  fetch: fetchBasket,
  count: basketCount,
  items: basketItems,
} = await useFimBasket()

// Make sure to sync basket items, maybe they were changed in Checkout
const { comingFromCheckout } = useCheckoutBasketSync()
if (comingFromCheckout.value) {
  fetchBasket()
}

const articleHeight = ref(96)

scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch([() => isBasketFlyoutOpen, () => basketItems], async () => {
    if (isBasketFlyoutOpen) {
      await nextTick()
      articleHeight.value = (
        articleCard?.value?.[0] as HTMLElement
      )?.clientHeight
    }
  })
})

const maxHeight = computed(() => articleHeight.value * showMaxArticleCount)

const badgeCount = computed(() =>
  basketCount.value ? basketCount.value : undefined,
)

const ariaLabel = computed(
  () =>
    `${$i18n.t('navigation.basket')}${
      badgeCount.value ? `: ${badgeCount.value}` : ''
    }`,
)

const badgeSize = computed(() =>
  basketCount
    ? (basketCount.value.toString().length as 1 | 2 | 3)
    : (1 as const),
)

const onLinkClick = (event: MouseEvent) => {
  trackMenuIconClick({ element: event.target as HTMLAnchorElement })
}
</script>
