import type { Category } from '@scayle/storefront-core'
import { toValue } from 'vue'
import { getIsoLanguage } from './locale'

// Hopefully a temporary workaround until we have a robust way to determine
// category based on slug or some other attribute
const contactLensesPaths = {
  de: '/kontaktlinsen',
  pl: '/soczewki-kontaktowe',
  cs: '/kontaktni-cocky',
  fr: '/lentilles-de-contact',
  it: '/lenti-a-contatto',
}

const lensesCarePaths = {
  de: '/kontaktlinsen/kontaktlinsenpflege',
  pl: '/soczewki-kontaktowe/srodki-do-pielegnacji-soczewek',
  cs: '/kontaktni-cocky/prostredky-na-peci-o-kontaktni-cocky',
  fr: '/lentilles-de-contact/entretien-des-lentilles-de-contact',
  it: '/lenti-a-contatto/cura-delle-lenti-a-contatto',
}

export const getContactLensesPath = (locale: string) => {
  const lang = getIsoLanguage(locale)
  return contactLensesPaths[lang as keyof typeof contactLensesPaths]
}

export const getLensesCarePath = (locale: string) => {
  const lang = getIsoLanguage(locale)
  return lensesCarePaths[lang as keyof typeof lensesCarePaths]
}

export const flattenCategories = (
  input: MaybeRefOrGetter<Category[] | Category | undefined>,
): Category[] => {
  const categories = toValue(input)
  if (!categories) {
    return []
  }
  return (Array.isArray(categories) ? [...categories] : [categories])
    .flatMap((category) => [
      category,
      ...flattenCategories([...(category.children ?? [])]),
    ])
    .filter((category) => !category.isHidden)
}

export const isBrandRoot = (category: MaybeRefOrGetter<Category | undefined>) =>
  Boolean(toValue(category)?.shopLevelCustomData?.Brand_Root)

export const isBrandCategory = (
  activeNode: MaybeRefOrGetter<Category | undefined>,
  categories: MaybeRefOrGetter<Category[] | Category | undefined>,
) => {
  const flattenedCategories: Record<number, Category> = Object.fromEntries(
    flattenCategories(categories).map((category) => [category.id, category]),
  )

  const [, ...ids] = toValue(activeNode)?.rootlineIds ?? []

  return ids?.some((id) => isBrandRoot(flattenedCategories?.[id]))
}
