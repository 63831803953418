<template>
  <Overlay
    :title="$t('lang_switcher.label')"
    title-classes="!text-xl !font-medium"
    :open="isLanguageSwitcherOverlayOpen"
    portal-target="#language-overlay"
    @update:open="closeLanguageSwitcherOverlay"
  >
    <ul>
      <li
        v-for="(lang, i) in shopLanguages"
        :key="i"
        class="border-b border-b-neutral-10 last:border-b-0"
      >
        <FimButton
          class="w-full pl-0"
          type="link"
          justify="start"
          @click="onLangClick(lang.code)"
        >
          <span class="flex items-center">
            <!--
            @todo: these icons don’t exist. Replace them with emoji?  
            <NuxtImg
              provider="local"
              class="mr-2 w-[22px]"
              :src="`/img/icons/language-flags/${lang.code}.png`"
              :alt="lang.label"
              loading="lazy"
            />
            -->
            <span>{{ lang.label }}</span>
          </span>
        </FimButton>
      </li>
    </ul>
  </Overlay>
</template>
<script setup lang="ts">
import useUiState from '~/composables/ui/useUiState'
import Overlay from '~/components/ui/Overlay.vue'
import FimButton from '~/components/ui/buttons/FimButton.vue'
import { useLocale } from '~/composables/useLocale'

/**
 * @todo is this component used at all?
 */

const { shopLanguages, switchLanguage, configuredLocale } = useLocale()

const { isLanguageSwitcherOverlayOpen, closeLanguageSwitcherOverlay } =
  useUiState()

const onLangClick = (lang: string) => {
  switchLanguage(lang, true)
  if (configuredLocale.value === lang) {
    closeLanguageSwitcherOverlay()
  }
}
</script>
