import { getQueryParam } from '~/utils/route'

/**
 * add branchId query param to sessionStorage so that it can be attatched to basket items
 */

export default defineNuxtRouteMiddleware(async (to, _from) => {
  if (import.meta.server) return

  const branchId = getQueryParam(to.query, 'branchId')

  if (!branchId) return

  sessionStorage.setItem('branchId', branchId)
})
