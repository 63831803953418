export type PdMessageData = {
  eventName: FimTrackingEvent
  eventProperties: PdTrackingPayload
}

const usePdScanEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackPdScan: (event: MessageEvent<PdMessageData>) => {
      const data = event?.data ?? {}
      if (data.eventName) {
        track(data.eventName, data.eventProperties || {})
      }
    },
  }
}

export default usePdScanEvents
