<template>
  <NuxtLink
    :to="linkTo"
    :aria-label="$t('global.home')"
    data-testid="header_logo"
    @click="resetSideNavigationEntry"
  >
    <LogoSvg />
  </NuxtLink>
</template>

<script setup lang="ts">
import useUiState from '~/composables/ui/useUiState'

defineProps({
  linkTo: {
    type: String,
    default: '/',
  },
  lightVariant: {
    type: Boolean,
    default: false,
  },
})

const { resetSideNavigationEntry } = useUiState()
</script>
