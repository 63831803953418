const useSearchResultEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => ({
  trackSearchResultView: (search: SearchResultData) => {
    track(
      'FielmannBasic_SearchResultView',
      {
        ...search,
      },
      { overridePageType: 'searchresults' },
    )
  },
  trackSearchResultClick: (
    search: SearchResultData,
    element: {
      label: string
      id: string
    },
  ) => {
    track(
      'FielmannBasic_SearchResultClick',
      {
        ...search,
        element,
      },
      { overridePageType: 'searchresults' },
    )
  },
})

export default useSearchResultEvents
