import type { ValuesType } from 'utility-types'

export const Status: Record<string, Package['deliveryStatus']> = {
  OPEN: 'open',
  SHIPMENT_PENDING: 'shipment_pending',
  DELEGATION_PENDING: 'delegation_pending',
  SHIPMENT_COMPLETED: 'shipment_completed',
  CANCELLATION_COMPLETED: 'cancellation_completed',
} as const

export type Status = ValuesType<typeof Status>

export const DeliveryProgress = {
  [Status.OPEN]: 25,
  [Status.SHIPMENT_PENDING]: 50,
  [Status.DELEGATION_PENDING]: 75,
  [Status.SHIPMENT_COMPLETED]: 100,
  [Status.CANCELLATION_COMPLETED]: 100,
} as Readonly<Record<Status, number>>

export const DELIVERY_STATUS_OPEN = 'open'
export const DELIVERY_STATUS_SHIPPMENT_PENDING = 'shipment_pending'
export const DELIVERY_STATUS_DELEGATION_PENDING = 'delegation_pending'
export const DELIVERY_STATUS_SHIPMEMT_COMPLETED = 'shipment_completed'
export const DELIVERY_STATUS_CANCELLATION_COMPLETED = 'cancellation_completed'
export const DELIVERY_STATUS_RETURNED = 'returned'
export const DELIVERY_STATUS_PARTIALLY_RETURNED = 'partially_returned'
export const DELIVERY_STATUS_CANCELLED = 'cancelled'
export const DELIVERY_STATUS_PARTIALLY_CANCELLED = 'partially_cancelled'
export const DELIVERY_STATUSES = [
  DELIVERY_STATUS_OPEN,
  DELIVERY_STATUS_SHIPPMENT_PENDING,
  DELIVERY_STATUS_DELEGATION_PENDING,
  DELIVERY_STATUS_SHIPMEMT_COMPLETED,
  DELIVERY_STATUS_CANCELLATION_COMPLETED,
  DELIVERY_STATUS_RETURNED,
  DELIVERY_STATUS_PARTIALLY_RETURNED,
  DELIVERY_STATUS_CANCELLED,
  DELIVERY_STATUS_PARTIALLY_CANCELLED,
  '', // fallback for orders without delivery status
] as const

export type DeliveryStatus = (typeof DELIVERY_STATUSES)[number]

export const paymentProvidersTyped = [
  'paypal_instant',
  'klarna_paylater',
  'ratepay_invoice',
  'computop_creditcard_dinersclub',
  'computop_creditcard_discover',
  'computop_creditcard_mastercard',
  'computop_creditcard_visa',
  'computop_creditcard',
  'ratepay_directdebit',
  'accounting',
  'payu',
  'blik',
  'twint',
  'p24',
] as const

export type PaymentProvider = (typeof paymentProvidersTyped)[number]

export const deliveryCarriers = [
  'POST_AT',
  'DHL',
  'INPOST_PL',
  'POST_CZ',
  'POST_CH',
] as const

export type DeliveryCarrier = (typeof deliveryCarriers)[number]

export const CARRIER_LABELS: Record<DeliveryCarrier, string> = {
  POST_AT: 'Post AT',
  DHL: 'DHL',
  INPOST_PL: 'InPost',
  POST_CZ: 'Česká Pošta',
  POST_CH: 'Die Post',
}

export const CARRIER_LOGO_PATHS: Record<DeliveryCarrier, string> = {
  POST_AT: '/img/carrier-logos/at-post.jpeg',
  DHL: '/img/carrier-logos/de-dhl.jpeg',
  INPOST_PL: '/img/carrier-logos/pl-post.svg',
  POST_CZ: '/img/carrier-logos/cz-post.svg',
  POST_CH: '/img/carrier-logos/ch-post.svg',
}
