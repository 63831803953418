import type { ValuesType } from 'utility-types'

export const CmsBaseFolder = {
  Content: 'content',
  PLP: 'product-categories',
  Recommendations: 'recommendations',
  Global: 'global',
  Overlays: 'overlays',
  Benefits: 'benefits',
  NDL: 'ndl',
  BotDetection: 'bot-detection',
} as const

export const isCmsBaseFolder = (
  input: any,
): input is ValuesType<typeof CmsBaseFolder> => {
  const values: string[] = Object.values(CmsBaseFolder)
  return typeof input === 'string' && values.includes(input)!
}


