import type {
  ProductSearchSuggestion,
  SearchV2With,
  TypeaheadProductSuggestion,
  TypeaheadSuggestionsEndpointResponseData,
} from '@scayle/storefront-nuxt'
import { debounce } from 'radash'

import { useStorefrontSearch } from '#storefront/composables'
import { DEBOUNCED_SEARCH_DURATION } from '~/constants/search'
import type { RpcStatus } from '~/types/rpc'
import { isCategorySuggestion, isProductSuggestion } from '~/utils/search'
import useSearchCounter from './tracking/useSearchCounter'

type SearchParams = Partial<{ categoryId: number; with: SearchV2With }>

export function useSearchData(
  key = 'storefront-search',
  params?: SearchParams,
) {
  const { incrementSearchCounter } = useSearchCounter()
  const searchQuery = useState<string>('search-query', () => '')

  const { data: allcategories } = useCategories({
    key: `all-categories`,
    options: { immediate: true, server: true },
  })

  const {
    getSearchRoute,
    localizedNavigateTo,
    getProductDetailRoute,
    buildCategorySuggestionRoute,
  } = useRouteHelpers()

  const {
    data,
    resolveSearch,
    getSearchSuggestions,
    pending: fetching,
    ...searchData
  } = useStorefrontSearch(searchQuery, { key, params })

  const searchProductsRpc = useRpcCall('searchProducts')

  const typeaheadData = useState<
    TypeaheadSuggestionsEndpointResponseData | undefined
  >(`${key}-data`, () => undefined)

  const typeaheadPending = useState(`${key}-typeahead-pending`, () => false)
  const typeaheadError = useState<unknown>(
    `${key}-typeahead-error`,
    () => undefined,
  )
  const typeaheadStatus = useState<RpcStatus>(
    `${key}-typeahead-error`,
    () => 'idle',
  )

  const getTypeaheadSuggestions = async () => {
    if (!searchQuery.value) {
      return
    }

    typeaheadPending.value = true
    typeaheadStatus.value = 'pending'

    try {
      typeaheadData.value = await searchProductsRpc({
        term: String(searchQuery.value),
        ...params,
      })
    } catch (e: unknown) {
      typeaheadError.value = e
    } finally {
      typeaheadPending.value = false
      typeaheadStatus.value = typeaheadError.value ? 'error' : 'success'
    }
  }

  const {
    brands: typeaheadBrands,
    categories: typeaheadCategories,
    totalCount: typeaheadTotalCount,
    products: typeaheadProducts,
    noSuggestions: noTypeaheadSuggestions,
  } = useTypeaheadSuggestions(typeaheadData)

  const allSuggestions = computed(() => data?.value?.suggestions ?? [])

  const suggestionProducts = computed(() => {
    return allSuggestions.value.filter(isProductSuggestion)
  })

  const suggestionCategories = computed(() => {
    return allSuggestions.value
      .filter(isCategorySuggestion)
      .filter(
        (suggestion) =>
          !isBrandCategory(
            suggestion.categorySuggestion.category,
            allcategories.value?.categories,
          ),
      )
  })

  const suggestionBrands = computed(() => {
    return allSuggestions.value
      .filter(isCategorySuggestion)
      .filter((suggestion) =>
        isBrandCategory(
          suggestion.categorySuggestion.category,
          allcategories.value?.categories,
        ),
      )
  })

  const suggestionTotalCount = computed(() => {
    return (
      suggestionProducts.value.length +
      suggestionBrands.value.length +
      suggestionCategories.value.length
    )
  })

  const noSuggestions = computed(() => suggestionTotalCount.value === 0)

  const resolveSearchAndRedirect = async () => {
    const resolved = await resolveSearch()

    if (!resolved?.type) {
      return await localizedNavigateTo(getSearchRoute(searchQuery.value))
    }

    if (isProductSuggestion(resolved)) {
      const { product } = resolved.productSuggestion
      return await localizedNavigateTo(getProductDetailRoute(product))
    }

    if (isCategorySuggestion(resolved)) {
      const route = buildCategorySuggestionRoute(resolved)
      return await localizedNavigateTo(route)
    }

    return await localizedNavigateTo(getSearchRoute(searchQuery.value))
  }

  const hasSearchQuery = computed(() => searchQuery.value?.length)

  const debouncedSuggestionSearch = debounce(
    { delay: DEBOUNCED_SEARCH_DURATION },
    async () => {
      if (!hasSearchQuery.value) {
        fetching.value = false
        return
      }
      incrementSearchCounter()
      await getSearchSuggestions()
    },
  )

  const debouncedTypeaheadSearch = debounce(
    { delay: DEBOUNCED_SEARCH_DURATION },
    async () => {
      if (!hasSearchQuery.value) {
        typeaheadPending.value = false
        return
      }
      incrementSearchCounter()
      await getTypeaheadSuggestions()
    },
  )

  const showSuggestionsLoader = computed(() => {
    return fetching.value && (!searchQuery.value || noSuggestions.value)
  })

  const resetSearch = () => {
    searchQuery.value = ''
    data.value = { suggestions: [] }
    typeaheadData.value = { suggestions: [] }
  }

  const withoutRxLensProducts = <
    T extends TypeaheadProductSuggestion | ProductSearchSuggestion,
  >(
    suggestions: T[],
  ) =>
    toValue(suggestions).filter(
      (product) =>
        !rxLensProductMasterKeys.includes(
          product.productSuggestion.product.masterKey || '',
        ),
    ) || []

  return {
    ...searchData,
    searchQuery,
    data,
    fetching: computed(() => fetching.value || typeaheadPending.value),
    resetSearch,
    suggestionBrands,
    suggestionProducts: computed(() =>
      withoutRxLensProducts(toValue(suggestionProducts)),
    ),
    suggestionCategories,
    suggestionTotalCount,
    noSuggestions,
    typeaheadBrands,
    typeaheadCategories,
    typeaheadTotalCount,
    typeaheadProducts: computed(() =>
      withoutRxLensProducts(toValue(typeaheadProducts)),
    ),
    typeaheadStatus,
    noTypeaheadSuggestions,
    resolveSearchAndRedirect,
    getTypeaheadSuggestions,
    hasSearchQuery,
    debouncedSuggestionSearch,
    debouncedTypeaheadSearch,
    showSuggestionsLoader,
  }
}
