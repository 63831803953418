const useSecondPairEvents = (
  track: (event: FimTrackingEvent, payload: FimTrackingPayload) => any,
) => ({
  trackProgressiveGlassesConversion: (
    element: EasySecondPairProgressiveGlassesConversion,
  ) => {
    track('FielmannBasic_CTAClick', {
      element,
    })
  },
})

export default useSecondPairEvents
