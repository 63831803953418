<template>
  <div class="flex items-center">
    <FimLink
      badge-position="wishlist"
      :to="localePath(routeList.wishlist)"
      type="loud"
      :aria-label="count ? wishlistAriaLabel : $t('navigation.wishlist')"
      :badge="count || undefined"
      :badge-size="count ? badgeSize : 1"
      :bold-on-active="false"
      data-testid="wishlist-link"
      data-tracking-id="navigation_wishlist"
      data-tracking-label="Wishlist"
      class="no-prerender flex flex-col justify-center px-2"
      @click="onLinkClick"
    >
      <LazyIconFielmannFavoriteInactive class="size-7 stroke-[1.5]" />
    </FimLink>
  </div>
</template>

<script setup lang="ts">
const $i18n = useI18n()
const { count } = await useWishlist()
const { trackMenuIconClick } = await useTrackingEvents()
const localePath = useFimLocalePath()

const wishlistAriaLabel = computed(() => {
  return `${$i18n.t('wishlist.heading')}${
    count.value ? `: ${count.value}` : ''
  }`
})

type BadgeSize = 1 | 2 | 3
const badgeSize = computed<BadgeSize>(() =>
  count ? (String(count.value).length as 1 | 2 | 3) : (1 as const),
)
const onLinkClick = (event: MouseEvent) => {
  trackMenuIconClick({ element: event.target as HTMLAnchorElement })
}
</script>
