export const prescriptionProps = ['sphere', 'cylinder', 'axis', 'pd'] as const
export type IPrescriptionProp = (typeof prescriptionProps)[number]

export interface IPrescriptionValue {
  right?: number
  left?: number
}

export type IPrescriptionValues = Record<IPrescriptionProp, IPrescriptionValue>

export type RxPrescriptionValue = Record<IPrescriptionProp, number> & {
  hpd?: number
}

export const rxProgressiveGlassesPrescriptionConversionType = [
  'distance',
  'reading',
] as const
export type RxProgressiveGlassesPrescriptionConversionType =
  (typeof rxProgressiveGlassesPrescriptionConversionType)[number] | 'progressive'

export interface RxPrescriptionValues {
  type?: RxProgressiveGlassesPrescriptionConversionType
  right: RxPrescriptionValue
  left: RxPrescriptionValue
}

export type RxPrescriptionDisplayValue = Record<IPrescriptionProp, string>
export type RxPrescriptionDisplayValues = {
  left: RxPrescriptionDisplayValue
  right: RxPrescriptionDisplayValue
}

export function isPrescriptionProp(prop: any): prop is IPrescriptionProp {
  return prescriptionProps.includes(prop)
}
