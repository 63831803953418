const comingFromCheckout = ref(false)

const useCheckoutBasketSync = () => {
  const observeCheckoutNavigation = () => {
    const router = useRouter()

    router.afterEach((_to, from) => {
      comingFromCheckout.value = from.path === routeList.checkout.path
    })
  }

  return {
    observeCheckoutNavigation,
    comingFromCheckout,
  }
}

export default useCheckoutBasketSync
