<template>
  <div
    class="animate-pulse bg-gray-200"
    :class="{
      'h-12': isButton,
      'h-8 md:w-64': isHeadline,
      'w-32': !fullWidth && isHeadline,
      'w-w-32 ': !fullWidth && isButton,
      'w-full': isFullWidth,
      'h-full': isFullHeight,
    }"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String as PropType<'button' | 'headline' | 'custom'>,
    enum: ['button', 'headline', 'custom'],
    default: 'button',
  },
  fullWidth: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  fullHeight: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const isButton = computed(() => props.type === 'button')
const isHeadline = computed(() => props.type === 'headline')
const isFullWidth = computed(() => props.fullWidth === true)
const isFullHeight = computed(() => props.fullHeight === true)
</script>
