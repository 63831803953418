import { rpcCall } from '@scayle/storefront-nuxt'
import type { LoggingParams } from '~/rpcMethods/logging'
import { getErrorMessage, getErrorExtras } from '../utils/error'

export const useLogging = () => {
  const nuxtApp = useNuxtApp()
  const currentShop = useCurrentShop()

  const method = (
    level: LoggingParams['level'],
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) =>
    rpcCall(
      nuxtApp,
      'logMessage',
      currentShop.value,
    )({
      message: getErrorMessage(message),
      extras: getErrorExtras(message, extras),
      level,
    })

  const log = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('log', message, extras)

  const warn = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('warn', message, extras)

  const error = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('error', message, extras)

  const debug = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('debug', message, extras)

  const table = (
    message: LoggingParams['message'],
    extras?: LoggingParams['extras'],
  ) => method('table', message, extras)

  const logger = { log, warn, error, debug, table }

  return {
    logger,
  }
}
