const useNotificationEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackNotification: (message: string) => {
      track('FielmannBasic_ApplicationNotification', {
        error: { notification: message },
      })
    },
  }
}

export default useNotificationEvents
