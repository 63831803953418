import {
  PromotionEffectType,
  type BuyXGetYEffect,
  type AutomaticDiscountEffect,
  type BasketResponseData,
} from '@scayle/storefront-nuxt'
import { sum, shake } from 'radash'

export const getPromotionStyle = (customData: Promotion['customData'] = {}) => {
  return shake(
    {
      color: customData?.style?.color,
      backgroundColor:
        customData?.style?.backgroundColor || customData?.colorHex,
    },
    (a) => !a,
  )
}

export const getPromotionContent = (
  customData: Promotion['customData'] = {},
  view: 'category' | 'product' | 'basket',
) => {
  const [title, text] = customData?.headlineParts || ([] as [string?, string?])

  if (!customData[view]) {
    return {
      title,
      text,
    }
  }

  const fallback =
    ('badgeLabel' in customData[view] &&
      (customData[view]?.badgeLabel as string)) ||
    ('ctaLabel' in customData[view] &&
      (customData[view]?.ctaLabel as string)) ||
    title

  return {
    title: 'title' in customData[view] ? customData[view]?.title : fallback,
    text: 'text' in customData[view] ? customData[view]?.text : text,
  }
}

export const isPromotionVisible = (
  customData: Promotion['customData'] = {},
  view: 'category' | 'product' | 'basket',
) => {
  if (!(view in customData)) {
    const { title } = getPromotionContent(customData, view)
    return Boolean(title)
  }
  return Boolean(customData[view])
}

export const isBuyXGetYType = (promotion?: Promotion | null) => {
  return promotion?.effect?.type === PromotionEffectType.BUY_X_GET_Y
}

export const isAutomaticDiscountType = (promotion?: Promotion | null) => {
  return promotion?.effect?.type === PromotionEffectType.AUTOMATIC_DISCOUNT
}

export const getVariantIds = (promotion?: Promotion | null): number[] => {
  if (!isBuyXGetYType(promotion) || !promotion) {
    return []
  }
  const { additionalData } = promotion.effect as BuyXGetYEffect
  return additionalData.variantIds
}

export const getAdditionalData = (
  promotion?: Promotion | null,
): AutomaticDiscountEffect['additionalData'] | undefined => {
  if (!isAutomaticDiscountType(promotion) || !promotion) {
    return
  }
  const { additionalData } = promotion.effect as AutomaticDiscountEffect
  return additionalData
}

export const getBasketTotalWithoutPromotions = (
  basket?: BasketResponseData,
) => {
  if (!basket) {
    return 0
  }
  const promotionReductions = sum(
    basket.cost.appliedReductions
      .filter(({ category }) => category === 'promotion')
      .map(({ amount }) => amount.absoluteWithTax),
  )

  return basket.cost.withTax + promotionReductions
}
