const usePageViewEvents = (
  track: (
    event: TrackingEvent,
    payload: TrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => ({
  trackPageView: (
    {
      eventName,
      contentName,
      title,
      pageType,
      pageTypeId,
      clickOrigin,
      error,
      meta,
    }: TrackContentViewEventParams,
    options?: MapToTrackingPayloadOptions,
  ) => {
    track(
      eventName,
      {
        content_name: contentName,
        title,
        page_type: pageType,
        page_type_id: pageTypeId,
        click_origin: clickOrigin,
        error,
        meta,
      },
      options,
    )
  },
})

export default usePageViewEvents
