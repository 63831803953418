<template>
  <Transition
    v-bind="activeClasses"
    enter-from-class="translate-x-full opacity-0"
    enter-to-class="-translate-x-0 opacity-100"
    leave-from-class="-translate-x-0"
    leave-to-class="translate-x-full opacity-0"
    :appear="appear"
  >
    <slot />
  </Transition>
</template>

<script setup lang="ts">
const props = defineProps(transitionsProps)
const { activeClasses } = useTransitions(props.duration)
</script>
