export const transitionsProps = {
  duration: {
    type: Number,
    default: 300,
    required: false,
    validator: (value: number) =>
      [100, 150, 200, 300, 500, 700, 1000].includes(value),
  },
  appear: {
    type: Boolean,
    default: false,
    required: false,
  },
}

export const useTransitions = (duration: number) => {
  const durationClasses = {
    100: 'duration-100',
    150: 'duration-150',
    200: 'duration-200',
    300: 'duration-300',
    500: 'duration-500',
    700: 'duration-700',
    1000: 'duration-1000',
  }

  const activeClasses = computed(() => ({
    enterActiveClass: `transform transition ${durationClasses[duration as keyof typeof durationClasses]} ease-in-out`,
    leaveActiveClass: `transform transition ${durationClasses[duration as keyof typeof durationClasses]} ease-in-out`,
  }))

  return {
    activeClasses,
  }
}
