import type { CentAmount } from '@scayle/storefront-nuxt'
// import { IntlMessageFormat } from 'intl-messageformat'
// import type { NuxtApp } from 'nuxt/app'
import type { ObjectWith } from './types'
import type domains from '~/config/domains'

export type UnderscoredLocale = keyof typeof domains
type PrimitiveType = string | number | boolean | null | undefined | Date

export type ObjectWithLocale = ObjectWith<{
  locale: string
  intlLocaleOverwrite?: string
}>

export type ObjectWithLocaleAndCurrency = ObjectWith<{
  locale: string
  intlLocaleOverwrite?: string
  currency: string
}>

const getLocale = (currentShop: ObjectWithLocale) =>
  getNormalizedLocale(currentShop?.intlLocaleOverwrite || currentShop.locale)

export const toLocaleString = (
  value: number,
  currentShop: ObjectWithLocale,
  formattingOptions?: Intl.NumberFormatOptions,
) => value.toLocaleString(getLocale(currentShop), formattingOptions)?.trim()

export const toLocaleDateTimeString = (
  dateValue: Date | number,
  currentShop: ObjectWithLocale,
  formattingOptions?: Intl.DateTimeFormatOptions,
) =>
  Intl.DateTimeFormat(getLocale(currentShop), formattingOptions).format(
    dateValue,
  )

export const toLocaleCurrency = (
  priceCent: CentAmount | number,
  currentShop: ObjectWithLocale,
  additionalFormattingOptions?: Intl.NumberFormatOptions,
) => {
  const price = priceCent / 100
  const locale = getLocale(currentShop)

  let defaultMinimumFractionDigits = 2
  let defaultMaximumFractionDigits = 2
  try {
    const localeOptions = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: unref(currentShop)?.currency,
    }).resolvedOptions()
    if (typeof localeOptions.minimumFractionDigits !== 'undefined') {
      defaultMinimumFractionDigits = localeOptions.minimumFractionDigits
    }
    if (typeof localeOptions.maximumFractionDigits !== 'undefined') {
      defaultMaximumFractionDigits = localeOptions.maximumFractionDigits
    }
  } catch {
    // todo add logger
  }

  const {
    minimumFractionDigits: minimumFractionDigitsOverride,
    maximumFractionDigits: maximumFractionDigitsOverride,
    ...additionalOptions
  } = additionalFormattingOptions || {}

  const maximumFractionDigits =
    minimumFractionDigitsOverride ??
    unref(currentShop)?.currencyFractionDigits ??
    defaultMaximumFractionDigits

  const minimumFractionDigits =
    maximumFractionDigitsOverride ??
    unref(currentShop)?.currencyFractionDigits ??
    defaultMinimumFractionDigits

  return price.toLocaleString(locale, {
    style: 'currency',
    currency: unref(currentShop).currency,
    minimumFractionDigits: Math.min(
      minimumFractionDigits,
      maximumFractionDigits,
    ),
    maximumFractionDigits,
    ...additionalOptions,
  })
}

export const getIsoLanguage = (locale: string) =>
  getNormalizedLocale(locale).split('-')[0]

export const getIsoCountry = (locale: string) => {
  const [lang, country] = getNormalizedLocale(locale).split('-')
  return country || lang.toUpperCase()
}

export const getNormalizedLocale = (locale: string, separator = '-') => {
  const [lang, country] = locale.split(/[-_.]/)
  if (country) {
    return `${lang.toLowerCase()}${separator}${country.toUpperCase()}`
  }
  return lang.toLowerCase()
}

// Helper function to map Alpha-2 codes to Alpha-3 codes
const countryCodeMap: { [key: string]: string } = {
  DE: 'DEU',
  AT: 'AUT',
  CH: 'CHE',
  PL: 'POL',
  CZ: 'CZE',
}

// Function to convert Alpha-2 country code to Alpha-3 code
export const getAlpha3CountryCode = (locale: string): string | undefined => {
  const isoCountryCode = getIsoCountry(locale)
  return countryCodeMap[isoCountryCode] || undefined
}
export const getGermanCountryName = (currentShop: ObjectWithLocale) => {
  const regionDisplayNames = new Intl.DisplayNames(['de'], { type: 'region' })
  const isoCountryCode = getIsoCountry(currentShop.locale)
  return regionDisplayNames.of(isoCountryCode)
}

// export const getLocalizedText = (
//   i18n: NuxtApp['$i18n'],
//   translationKey: string,
//   args: Record<string, PrimitiveType>,
// ) => {
//   const lang = getIsoLanguage(i18n.locale.value)
//   const msgTemplate = new IntlMessageFormat(i18n.t(translationKey), lang)
//   return msgTemplate.format<string>(args)
// }
