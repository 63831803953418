import {
  mapTrackingDataForEvent,
  mapTrackingDataForFimEvent,
  mapTrackingDataForFimPageViewEvent,
} from './tracking/helpers'
import useSearchResultEvents from './tracking/events/useSearchResultEvents'
import useSecondPairEvents from './tracking/events/useSecondPairEvents'

/**
 * Higher order composable which serves as a single entry point to unify different ecommerce tracking events
 */
export const useTrackingEvents = () => {
  const nuxtApp = useNuxtApp()
  const { $tracking, $i18n, $fimLocalePath, $currentShop } = nuxtApp
  const { pageState } = usePageState()
  const isBotTraffic = pageState.value.isBotTraffic
  const route = useRoute()
  const log = useLog('useTrackingEvents')
  const { getProductDetailRoute } = useRouteHelpers()
  const userPromise = useUser({ immediate: true })
  const { user, isLoggedIn } = userPromise

  // FIM tracking
  const trackFim = async (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    _options?: MapToTrackingPayloadOptions,
  ) => {
    if (import.meta.server) {
      return
    }

    try {
      await userPromise
      const options = {
        ..._options,
        context: nuxtApp,
        user: toValue(user),
        isLoggedIn: toValue(isLoggedIn),
        isBotTraffic,
        $currentShop,
        $i18n,
        pageState,
        route,
      }

      $tracking.push(mapTrackingDataForFimEvent(event, payload, options))
    } catch (error) {
      log.error('Tracking failed:', error)
    }
  }

  // When tracking page views we just need a subset of tracking data SCFIM-1098
  const trackFimPageView = async (
    event: TrackingEvent,
    payload: TrackingPayload,
    _options?: MapToTrackingPayloadOptions,
  ) => {
    if (import.meta.server) {
      return
    }

    try {
      await userPromise

      const options = {
        ..._options,
        context: nuxtApp,
        user: toValue(user),
        isLoggedIn: toValue(isLoggedIn),
        isBotTraffic,
        $currentShop,
        $i18n,
        pageState,
        route,
      }

      $tracking.push(
        mapTrackingDataForFimPageViewEvent(event, payload, options),
      )
    } catch (error) {
      log.error('Tracking failed:', error)
    }
  }

  // Scayle tracking
  const track = async (
    event: TrackingEvent,
    payload: TrackingPayload,
    _options?: MapToTrackingPayloadOptions,
  ) => {
    if (import.meta.server) {
      return
    }
    try {
      await userPromise

      const options = {
        ..._options,
        context: nuxtApp,
        user: toValue(user),
        isLoggedIn: toValue(isLoggedIn),
        isBotTraffic,
        $currentShop,
        $i18n,
        pageState,
        route,
      }

      userPromise.then(() => {
        $tracking.push(mapTrackingDataForEvent(event, payload, options))
      })
    } catch (error) {
      log.error('Tracking failed:', error)
    }
  }

  /**
   * NOTE: Important considerations:
   * sub-composable(s) such as useBasketEvents, etc. should not be utilized directly
   * instead make use of the higher order composable (useTrackingEvents) to keep things clean
   * new events should be added to relevant sub-composable to ensure DRY code and separation of concerns
   */
  return extendPromise(
    userPromise.then(() => ({})),
    {
      ...useShopEvents(track, $currentShop),
      ...useBasketEvents(track),
      ...useWishlistEvents(track),
      ...useProductEvents(track, {
        localePath: $fimLocalePath,
        $helpers: { getProductDetailRoute },
      }),
      ...useCheckoutEvents(track),
      ...useSearchResultEvents(trackFim),
      ...usePurchaseEvents(track, $currentShop),
      ...useFilterEvents(track),
      ...usePromotionEvents(track),
      ...usePdScanEvents(trackFim),
      ...useVTOEvents(trackFim),
      ...useCTAEvents(trackFim),
      ...useNewsletterEvents(trackFim),
      ...useCustomerEvents(track),
      ...useNotificationEvents(trackFim),
      ...useButtonClickEvents(trackFim),
      ...useLinkClickEvents(trackFim),
      ...useContentViewEvents(track),
      ...usePageViewEvents(trackFimPageView),
      ...useMenuEvents(trackFim),
      ...useFooterEvents(trackFim),
      ...useRxConfiguratorEvents(trackFim),
      ...useHearingTestEvents(trackFim),
      ...useSecondPairEvents(trackFim),
    },
  )
}
