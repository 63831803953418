import type { BasketItem, Product, Variant } from '@scayle/storefront-nuxt'

const collectBasketItems = (items: any[]) => {
  return items.map(
    (basketItem: any, idx: number): ProductListData => ({
      product: basketItem.product,
      quantity: basketItem.quantity,
    }),
  )
}

const useCheckoutEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
) => ({
  trackBeginCheckout: (items: BasketItem<Product, Variant>[] = []) => {
    track('FielmannBasic_EC_BeginCheckout', {
      items: collectBasketItems(items),
    })
  },
  collectBasketItems,
})
export default useCheckoutEvents
