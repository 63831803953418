<template>
  <div
    class="relative z-40"
    @mouseenter="emit('mouseenter')"
    @mouseleave="emit('mouseleave')"
  >
    <div class="inline-flex size-full items-center justify-center">
      <slot name="action" />
    </div>
    <FadeInFromBottomTransition appear>
      <div
        v-show="isOpen"
        class="absolute right-0 z-30 min-w-max overflow-hidden border border-neutral-40 bg-white"
        :class="contentClasses"
      >
        <slot name="content" />
      </div>
    </FadeInFromBottomTransition>
  </div>
</template>

<script setup lang="ts">
defineProps({
  isOpen: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  disablePopoverContent: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  contentClasses: {
    type: String as PropType<string>,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'mouseenter' | 'mouseleave'): void
}>()

defineOptions({ name: 'AppPopover' })
</script>
