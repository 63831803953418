import {
  type Order,
  type Price,
  type WishlistResponseData,
  type BasketResponseData,
  getLatestCategory,
  type CentAmount,
} from '@scayle/storefront-nuxt'
import { timeout } from '@scayle/storefront-core'
import type { NuxtApp } from 'nuxt/app'
import {
  getConcatedVoucherCode,
  getFloatedReducedPriceForCategoryOrNull,
  getGlobalVouchers,
  applyAbsoluteVouchers,
  getProductCategoryTrackingValues,
} from '../composables/tracking/helpers'
import { categoryTrackingAttributes } from '../composables/tracking/helpers/trackingMaps'
import { groupItems } from './order'
import { isRxMainItem, getItemGroupId } from '~/utils/rx'
import { sumUpPrice, getOriginalPrice } from '~/utils/price'
import type { ProductCategories } from '~/utils/product'
import useSearchCounter from '~/composables/tracking/useSearchCounter'
import {
  ADDITIONAL_TRACKING_EVENTS,
  ECOMMERCE_TRACKING_EVENTS,
  LIST_ATTRIBUTION_EVENTS,
  MULTIPLE_PROMOTION_EVENTS,
  PROMOTION_TRACKING_EVENTS,
} from '~/types/tracking'

// TODO: Add tests

const toFloat = (price: number) => price / 100

/**
 * Compares data of type WishlistResponseData (wishlist or basket)
 * @param oldData previous data state (eg wishlist data {items, key})
 * @param newData new data state (eg wishlist data {items, key})
 * @returns boolean if a tracking significant change is detected
 */
export const didWishlistOrBasketDataChange = (
  oldData: BasketResponseData | WishlistResponseData | undefined,
  newData: BasketResponseData | WishlistResponseData | undefined,
): boolean => {
  return !isEqual(
    {
      items: oldData?.items.map((item) => ({
        productId: item.product?.id,
        variantId: item.variant?.id,
        price: item.product?.priceRange,
        quantity: (item as any).quantity,
        soldOut: item.product?.isSoldOut,
      })),
      key: oldData?.key,
    },
    {
      items: newData?.items.map((item) => ({
        productId: item.product?.id,
        variantId: item.variant?.id,
        price: item.product?.priceRange,
        quantity: (item as any).quantity,
        soldOut: item.product?.isSoldOut,
      })),
      key: newData?.key,
    },
  )
}

export const getEmailHash = async (email: string | undefined) => {
  if (!email) {
    return ''
  }
  const hashBuffer = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(email.replace(/ /g, '')?.toLowerCase()),
  )
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

const getFimItemQuantityFromOrder = (
  order: Order,
  variantId: number,
  itemGroupId: string,
): number | undefined =>
  order.items?.filter(
    (value) =>
      value.variant.id === variantId && itemGroupId === getItemGroupId(value),
  ).length

export const formatPrice = (
  value: number,
  locale: string,
  currency: string,
): string => {
  const currencyFractionDigits = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).resolvedOptions().maximumFractionDigits

  return toFloat(value).toLocaleString(locale, {
    minimumFractionDigits: currencyFractionDigits,
    useGrouping: false,
  })
}

const getNetPrice = (
  value: number,
  price: ObjectWith<{ tax: Price['tax'] }>,
) => {
  const taxRate = price.tax.vat.rate ?? 0.19

  return toFloat(Math.floor((value / (1 + taxRate)) * 100))
}

export const mapCbdDataToEcommerceData = (
  cbdData: Order,
  currency: string,
  i18n: NuxtApp['$i18n'],
): EcommerceData['ecommerce'] => {
  const shippingCost = cbdData.cost?.appliedFees?.find(
    ({ category }) => category === 'delivery',
  )

  const distinctGroupedItems = groupItems(cbdData.items)?.filter(
    (item, index, arr) =>
      arr.findIndex(
        (t) =>
          t.variant.id === item.variant.id &&
          getItemGroupId(t) === getItemGroupId(item),
      ) === index,
  )

  // Apply absolute vouchers to items
  const items = applyAbsoluteVouchers(cbdData, distinctGroupedItems)

  const globalVouchers = getGlobalVouchers(items)

  const result: EcommerceData['ecommerce'] = {
    transaction_id: `${cbdData.id}`,
    customer_id: `${cbdData!.customer!.id}`,
    value: +(cbdData.cost.withoutTax / 100).toFixed(2),
    value_gross: +(cbdData.cost.withTax / 100).toFixed(2),
    tax: +formatPrice(cbdData.cost.tax.vat?.amount || 0, 'en-EN', currency),
    shipping: +formatPrice(
      shippingCost?.amount.withoutTax || 0,
      'en-EN',
      currency,
    ),
    currency,
    payment: cbdData.payment?.[0].key || '',
    coupon: getConcatedVoucherCode({
      appliedReductions: globalVouchers,
    }),
    items:
      items?.map((basketItem: any, index: number): EcommerceItem => {
        const price = isRxMainItem(basketItem)
          ? sumUpPrice([
              basketItem.price,
              ...(basketItem?.customData?.items?.map(
                (item: any) => item.price,
              ) ?? []),
            ])
          : basketItem.price

        const itemCategories = getProductCategoryTrackingValues(
          basketItem.product,
          i18n,
          basketItem.variant,
        )
        return {
          price_gross: toFloat(price.withTax),
          sale_discount: getFloatedReducedPriceForCategoryOrNull(price, 'sale'),
          coupon: getConcatedVoucherCode(price),
          discount: getNetPrice(
            getFloatedReducedPriceForCategoryOrNull(price, 'voucher'),
            price,
          ),
          campaign_discount: getFloatedReducedPriceForCategoryOrNull(
            price,
            'campaign',
          ),
          original_price: toFloat(
            price?.appliedReductions?.length
              ? getOriginalPrice(price)
              : price.withTax,
          ),

          item_category_id:
            getLatestCategory(
              basketItem.product.categories,
            )?.categoryId.toString() || '',
          item_id: `${basketItem.product.id}`,
          item_name: basketItem.product.name,
          price: +formatPrice(price.withoutTax, 'en-EN', currency),
          item_brand: basketItem.product.attributes?.brand?.values?.label,
          item_brand_id: `${basketItem.product.attributes?.brand?.values?.id}`,
          item_variant: `${basketItem.variant.id}`,
          key: basketItem.key,
          quantity:
            getFimItemQuantityFromOrder(
              cbdData,
              basketItem.variant.id,
              getItemGroupId(basketItem),
            ) || 0,
          index: index + 1,
          ...itemCategories,
        }
      }) ?? [],
  }

  if (cbdData.confirmedAt) {
    const orderTimestamp = new Date(cbdData.confirmedAt).getTime().toString()
    return { ...result, orderTimestamp }
  }

  return result
}

export const addTrackingAttributesToQuery = (
  attributes: string[],
  category?: ProductCategories,
): string[] => {
  let trackingAttributes: string[]
  if (!category) {
    trackingAttributes = Object.values(categoryTrackingAttributes).flatMap(
      (trackingAttribute) => trackingAttribute,
    )
  } else {
    trackingAttributes = categoryTrackingAttributes[category]
  }
  const attributesWihoutDuplicates = new Set([
    ...attributes,
    ...trackingAttributes,
  ])
  return Array.from(attributesWihoutDuplicates.values())
}

export const buildSearchData = (
  searchTerm: string,
  searchResultsProducts: number = 0,
  searchResultsCategories: number,
  searchResultsPages: number = 1,
  location: 'search_results_flyout' | 'search_results_page',
  searchDestination?: boolean,
): SearchResultData => {
  const { searchCounter } = useSearchCounter()

  return {
    search: {
      search_term: searchTerm,
      search_results_products: searchResultsProducts,
      search_results_categories: searchResultsCategories,
      search_results_pages: searchResultsPages,
      search_count: searchCounter.value,
      search_location: location,
      search_destination: searchDestination
        ? `/search/?term=${searchTerm}`
        : undefined,
    },
  }
}

export function isAdditionalTrackingEvent(
  event: TrackingEvent,
): event is AdditionalTrackingEvent {
  return ADDITIONAL_TRACKING_EVENTS.includes(event as AdditionalTrackingEvent)
}

export function isEcommerceTrackingEvent(
  event: TrackingEvent,
): event is EcommerceTrackingEvent {
  return ECOMMERCE_TRACKING_EVENTS.includes(event as EcommerceTrackingEvent)
}

export function isPromotionTrackingEvent(
  event: TrackingEvent,
): event is PromotionTrackingEvent {
  return PROMOTION_TRACKING_EVENTS.includes(event as PromotionTrackingEvent)
}

export function isMultiplePromotionEvents(
  event: TrackingEvent,
): event is MuliplePromotionTrackingEvent {
  return MULTIPLE_PROMOTION_EVENTS.includes(
    event as MuliplePromotionTrackingEvent,
  )
}

export function isListAttributionTrackingEvent(
  event: TrackingEvent,
): event is ListAttributionTrackingEvent {
  return LIST_ATTRIBUTION_EVENTS.includes(event as ListAttributionTrackingEvent)
}

export function isEcommerceTrackingEventWithPromotion(
  event: TrackingEvent,
): event is EcommerceTrackingEvent {
  return [
    'FielmannBasic_EC_AddToCart',
    'FielmannBasic_EC_AddToWishlist',
    'FielmannBasic_EC_ItemView',
    'FielmannBasic_EC_ItemViewList',
    'FielmannBasic_EC_RemoveFromCart',
    'FielmannBasic_EC_RemoveFromWishlist',
    'FielmannBasic_EC_SelectItem',
  ].includes(event)
}

export function isProductImpressionsData(
  data: TrackingPayload | MultipleActionData,
): data is MultipleActionData {
  return (data as MultipleActionData).items !== undefined
}

export const getRXFullPrice = (payload: CartViewPayload) => {
  const { configurationItems = [] } = payload.customData

  const totalPriceWithTax = configurationItems.reduce(
    (partialSum: number, item) => partialSum + (item.price?.withTax || 0),
    payload.price.unit.withTax,
  )
  const totalPriceWithoutTax = configurationItems.reduce(
    (partialSum: number, item) => partialSum + (item.price?.withoutTax || 0),
    payload.price.unit.withoutTax,
  )

  const { tax, appliedReductions, currencyCode } = payload.price.total
  return {
    withTax: totalPriceWithTax as CentAmount,
    withoutTax: totalPriceWithoutTax as CentAmount,
    tax,
    appliedReductions,
    currencyCode,
  }
}

export const getGoogleTagProperty = (
  key: string,
  currentShop?: MaybeRefOrGetter<ObjectWith<{ googleTagId?: string }>>,
): Promise<string | undefined> => {
  const googleTagId = toValue(currentShop)?.googleTagId

  if (
    import.meta.client &&
    googleTagId &&
    !document.querySelector(`script[src*="/gtag/"]`)
  ) {
    return Promise.reject(new Error('Analytics script unavailable'))
  }

  return timeout(
    500,
    new Promise((resolve, reject) => {
      const method =
        'gtag' in window && typeof window.gtag === 'function'
          ? window.gtag
          : undefined

      if (!googleTagId) {
        return reject(new Error('Missing Google-Tag-Id'))
      }

      if (!method) {
        return reject(new Error('Tagmanager not initialized'))
      }

      method('get', googleTagId, key, function (value: string | undefined) {
        resolve(value)
      })
    }),
  )
}
