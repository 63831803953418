<template>
  <div id="navigation-items-list" class="relative block">
    <nav class="flex w-full space-x-5">
      <FimLink
        v-for="(link, idx) in navigationTree"
        :id="getFormattedId(link.label)"
        :key="idx"
        :to="link.href ? localePath(link.href) : '/'"
        :data-prefetch="link.prefetch"
        badge-placement="top"
        active-class="after:bg-black"
        class="border-bottom-after h-12 whitespace-nowrap"
        text-size="lg"
        :bold-on-active="false"
        :class="{
          'after:bg-black': flyoutMenuOpen && hoveredItem === idx,
        }"
        @mouseenter="
          () => {
            hoveredItem = idx
            $emit('mouseenter:item', link)
          }
        "
        @click="onLinkClick"
      >
        {{ link.label }}
      </FimLink>
    </nav>
    <div
      id="flyout-overscroll-container"
      aria-hidden="true"
      class="absolute h-4 w-full scale-125 bg-transparent"
    />
  </div>
</template>

<script setup lang="ts">
import type { NavigationLink } from '~/rpcMethods/navigation'
import FimLink from '~/components/ui/links/FimLink.vue'

defineProps({
  fetching: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  navigationTree: {
    type: Array as PropType<NavigationLink[]>,
    default: () => [],
  },
  items: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  flyoutMenuOpen: {
    type: Boolean,
  },
})

defineEmits(['mouseenter:item'])

const { trackMenuItemClick } = await useTrackingEvents()

const hoveredItem = ref(-1)
const onLinkClick = (event: MouseEvent) => {
  trackMenuItemClick({ element: event.target as HTMLButtonElement })
}
const getFormattedId = (text: string) => formatId(text, 'menu-item')

const localePath = useFimLocalePath()
</script>

<style lang="scss">
.border-bottom-after::after {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  left: 0;
  right: 0;
  top: calc(100% + 0.25rem);
  z-index: 1000;
}
</style>
