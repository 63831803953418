export default {
  de_AT: (process.env.DOMAIN_DE_AT ||
    process.env.NUXT_STOREFRONT_STORES_10001_DOMAIN)!,
  de_DE: (process.env.DOMAIN_DE_DE ||
    process.env.NUXT_STOREFRONT_STORES_10004_DOMAIN)!,
  cs_CZ: (process.env.DOMAIN_CS_CZ ||
    process.env.NUXT_STOREFRONT_STORES_10005_DOMAIN)!,
  pl_PL: (process.env.DOMAIN_PL_PL ||
    process.env.NUXT_STOREFRONT_STORES_10006_DOMAIN)!,
  de_CH: (process.env.DOMAIN_DE_CH ||
    process.env.NUXT_STOREFRONT_STORES_10008_DOMAIN)!,
  fr_CH: (process.env.DOMAIN_FR_CH ||
    process.env.NUXT_STOREFRONT_STORES_10009_DOMAIN)!,
  it_CH: (process.env.DOMAIN_IT_CH ||
    process.env.NUXT_STOREFRONT_STORES_10010_DOMAIN)!,
}
