import type { NuxtApp } from 'nuxt/app'
import {
  contactLensProps,
  getFormattedLensValue,
  isContactLensProp,
  type ContactLensDisplayValue,
  type ContactLensDisplayValues,
  type ContactLensProp,
  type ContactLensValue,
  type ContactLensValues,
} from './contactLenses'
import { formatPrescriptionValue } from './rx'
import {
  prescriptionProps,
  type IPrescriptionProp,
  type RxPrescriptionDisplayValue,
  type RxPrescriptionDisplayValues,
  type RxPrescriptionValue,
  type RxPrescriptionValues,
} from './rxTypes'

export type CorrectionalPrescriptionValue =
  | ContactLensValue
  | RxPrescriptionValue

export type CorrectionalPrescriptionValues =
  | Partial<ContactLensValues>
  | RxPrescriptionValues

export const formatSightSpecification = (
  sightSpecProp: IPrescriptionProp | ContactLensProp,
  value: number | string | undefined,
  currentShop: NuxtApp['$currentShop'],
  showUnit = false,
) => {
  if (isContactLensProp(sightSpecProp)) {
    return getFormattedLensValue(sightSpecProp, value, currentShop, showUnit)
  } else if (typeof value !== 'string') {
    return formatPrescriptionValue(sightSpecProp, value, currentShop, showUnit)
  }
  return value
}

export const isRxPrescriptionValue = (
  prescriptionValues: RxPrescriptionValue | ContactLensValue,
): prescriptionValues is RxPrescriptionValue => {
  return 'pd' in prescriptionValues
}

export const orderAndFormatSightSpecification = (
  prescriptionValues: RxPrescriptionValue | ContactLensValue,
  currentShop: NuxtApp['$currentShop'],
  showUnit = false,
) => {
  if (isRxPrescriptionValue(prescriptionValues)) {
    return prescriptionProps.reduce(
      (displayValues, curProp) => ({
        ...displayValues,
        [curProp]: formatSightSpecification(
          curProp,
          prescriptionValues[curProp],
          currentShop,
          showUnit,
        ),
      }),
      {} as RxPrescriptionDisplayValue,
    )
  }
  return contactLensProps.reduce((displayValues, curProp) => {
    if (curProp in prescriptionValues) {
      displayValues[curProp] = formatSightSpecification(
        curProp,
        prescriptionValues[curProp],
        currentShop,
        showUnit,
      )
    }
    return displayValues
  }, {} as ContactLensDisplayValue)
}

export function formatSightSpecificationForDisplay(
  prescriptionValues: RxPrescriptionValues,
  currentShop: NuxtApp['$currentShop'],
  showUnit?: boolean,
): RxPrescriptionDisplayValues

export function formatSightSpecificationForDisplay(
  prescriptionValues: Partial<ContactLensValues>,
  currentShop: NuxtApp['$currentShop'],
  showUnit?: boolean,
): ContactLensDisplayValues

export function formatSightSpecificationForDisplay(
  prescriptionValues: CorrectionalPrescriptionValues,
  currentShop: NuxtApp['$currentShop'],
  showUnit = false,
): RxPrescriptionDisplayValues | ContactLensDisplayValues {
  return {
    ...(prescriptionValues.right && {
      right: orderAndFormatSightSpecification(
        prescriptionValues.right,
        currentShop,
        showUnit,
      ),
    }),
    ...(prescriptionValues.left && {
      left: orderAndFormatSightSpecification(
        prescriptionValues.left,
        currentShop,
        showUnit,
      ),
    }),
  }
}
