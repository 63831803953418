export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const route = useRoute()
    const dataDogTestStorage = useSessionStorage<boolean>('dataDogTest', false)

    if (route?.query?.dataDogTest === 'true') {
      dataDogTestStorage.value = true
    }

    return {
      provide: {
        dataDogTest: {
          isEnabled: computed(() => dataDogTestStorage.value),
        },
      },
    }
  },
})
export type DataDogTestPlugin = {
  isEnabled: ComputedRef<boolean>
}
