import type { RpcMethodReturnType } from '@scayle/storefront-core'

type RxProducts = Awaited<RpcMethodReturnType<'getRxProducts'>>

export const useRxProducts = () => {
  const promise = useAsyncRpc('getRxProducts', undefined, {
    server: true,
    immediate: true,
    dedupe: 'defer',
    getCachedData(key, nuxtApp) {
      const hydrationData = nuxtApp.isHydrating
        ? nuxtApp.payload.data[key]
        : nuxtApp.static.data[key]
      return (hydrationData ??
        nuxtApp._asyncData[key]?.data.value) as RxProducts
    },
  })
  const { data, status, error, execute } = promise

  const rxLensTypes = computed(() => data.value?.rxLensTypes ?? [])
  const rxLensQualityIndex = computed(
    () => data.value?.rxLensQualityIndex ?? [],
  )
  const rxTintingTypes = computed(() => data.value?.rxTintingTypes ?? [])
  const rxFreeServiceProduct = computed(
    () => data.value?.rxFreeServiceProduct ?? [],
  )
  const rxCheapestLensQualityIndexPrice = computed(() => {
    const prices = rxLensQualityIndex.value
      .filter((p) => p.priceRange?.min?.withTax)
      .map((p) => p.priceRange?.min?.withTax as number)
    return prices.length ? prices.sort((a, b) => a - b)[0] : 0
  })

  return extendPromise(
    promise.then(() => ({})),
    {
      rxLensTypes,
      rxLensQualityIndex,
      rxTintingTypes,
      rxFreeServiceProduct,
      rxCheapestLensQualityIndexPrice,
      status,
      error,
      fetching: computed(() => status.value === 'pending'),
      fetch: execute,
    },
  )
}
