import type { FontWeightType, HeadlineLook } from '~/constants/ui'

/**
 * Helper to generate a CSS class based on the @param classOptionsMap returning a string in the format "class-for-mobile breakpoint:class-for-this-breakpoint"
 * @returns string
 */
export const getClassesFromOptionsMap = ({
  classOptionsMap,
  mobile,
  desktop,
  breakpoint = 'md',
}: {
  classOptionsMap: Record<string, any>
  mobile: any
  desktop: any
  breakpoint: string
}) => {
  const mobileClass = mobile ? classOptionsMap[String(mobile)] : ''
  const desktopClass = desktop
    ? `${breakpoint}:${classOptionsMap[String(desktop)]}`
    : ''
  return [mobileClass, desktopClass].join(' ')
}

export const getHeadlineClassNames = (
  look?: HeadlineLook,
  weight?: FontWeightType,
) => {
  const classes = {
    'font-normal': weight === 'normal',
    'font-medium': weight === 'medium',
    'font-semibold': weight === 'semi-bold',
    'font-bold': weight === 'bold',
    'text-6xl tracking-[-0.3px]': look === 'h1-6xl',
    'text-5xl tracking-[-0.3px]': look === 'h1-5xl',
    'text-4xl tracking-[-0.3px]': look === 'h1-4xl',
    'text-3xl md:leading-10 tracking-[-0.3px]': look === 'h1',
    'text-2xl md:leading-[30px] tracking-[-0.1px]': look === 'h2',
    'text-xl md:leading-[26px] tracking-normal': look === 'h3',
    'text-lg md:leading-[24px] tracking-normal': look === 'h4',
    'text-base md:leading-[22px] tracking-normal': look === 'h5',
    'text-sm md:leading-[18px] tracking-[0.2px]': look === 'h6',
  }

  return Object.entries(classes)
    .filter(([, val]) => val)
    .map(([classNames]) => classNames)
    .join(' ')
}
