<template>
  <FimLink
    :badge="numberOfRopoArticles"
    badge-position="ropo"
    :badge-size="1"
    :bold-on-active="false"
    class="header-main-menu__user px-2"
    :aria-label="$i18n.t('myaccount.menu_title')"
    :to="localePath(routeList.ropoBasket)"
    size="sm"
  >
    <LazyIconFielmannCalendar class="size-7" />
  </FimLink>
</template>

<script setup lang="ts">
const $i18n = useI18n()
const localePath = useFimLocalePath()
const { numberOfRopoArticles } = await useRopoBasketState()
</script>
