const useMenuEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackMenuItemClick: ({ element }: FimButtonTrackingPayload) => {
      track('FielmannBasic_MenuItemClick', { element })
    },
    trackMenuIconClick: ({ element }: FimButtonTrackingPayload) => {
      track('FielmannBasic_MenuIconClick', { element })
    },
  }
}

export default useMenuEvents
