const useFilterEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => ({
  trackNewsletter: (email: string) => {
    track('FielmannBasic_LeadFormSubmit', {
      user: { email },
      form: { identifier: 'Newsletter Subscription' },
    })
  },
})

export default useFilterEvents
