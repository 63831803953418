const searchCounter = ref(0)

const useSearchCounter = () => {
  const incrementSearchCounter = () => {
    searchCounter.value += 1
  }

  return {
    searchCounter: readonly(searchCounter),
    incrementSearchCounter,
  }
}

export default useSearchCounter
