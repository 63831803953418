/**
 * Get typed storyblok references
 */

import type { FontWeightType, HeadlineLook, HeadlineTag } from '~/constants/ui'
import { isObjectFit, isObjectPosition } from '~/constants/ui'
import type {
  SbHeadline,
  SbImage,
  SbLink,
  SbText,
  Sbasset,
} from '~/storyblok/types/storyblok'
import {
  getLinkTarget,
  isValidLink,
  resolvSbLink,
} from '~/storyblok/utils/links'

export function parseColor<
  T extends string | undefined,
  R = T extends string ? string : string | undefined,
>(
  value:
    | string
    | {
        color?: string
        plugin?: string
        _uid?: string
      }
    | undefined,
  defaultValue?: T,
): R {
  if (!value) {
    return defaultValue as R
  }

  if (typeof value === 'string') {
    return value?.trim()?.startsWith('#') ? (value as R) : (defaultValue as R)
  }

  return (
    value?.color?.trim()?.startsWith('#') ? value.color : defaultValue
  ) as R
}

export const getAssetValue = (
  value: Sbasset | string | undefined,
): Sbasset | undefined => {
  if (typeof value === 'string') {
    return {
      id: 0,
      filename: value,
      name: value.replace(/.+\/(.*)\..+/, '$1'),
    }
  }
  return value
}

export const parseHeadlineRef = (
  input: SbHeadline[] | SbHeadline | undefined,
  defaultValues?: {
    tag?: HeadlineTag
    look?: HeadlineLook
    weight?: FontWeightType
    color?: string
  },
) => {
  const ref = Array.isArray(input) ? input[0] : input

  if (!ref || !ref.text) {
    return undefined
  }

  const colorHex = parseColor(ref?.color)
  return {
    ...ref,
    text: ref.text,
    tag: toHeadlineTag(ref?.tag, defaultValues?.tag),
    look: toHeadlineLook(ref?.look, defaultValues?.look),
    weight: toFontWeightType(ref?.weight, defaultValues?.weight),
    color: colorHex || defaultValues?.color,
  }
}

export const getImageRef = (input: SbImage[] | SbImage | undefined) => {
  return Array.isArray(input) ? input[0] : input
}

export const parseImageRef = (input: SbImage[] | SbImage | undefined) => {
  const ref = getImageRef(input)
  if (!ref || (!ref.desktop_image && !ref.mobile_image)) {
    return undefined
  }
  return {
    ...ref,
    src: ref.desktop_image?.filename || ref.mobile_image?.filename,
    srcMobile: ref.mobile_image?.filename,
    srcDesktop: ref.desktop_image?.filename,
    focusMobile: getFocusPoint(ref?.desktop_image),
    focusDesktop: getFocusPoint(ref?.mobile_image),
    objectFit: isObjectFit(ref?.object_fit) ? ref.object_fit : undefined,
    objectPosition: isObjectPosition(ref?.object_position)
      ? ref.object_position
      : undefined,
    alt: ref?.desktop_image?.alt ?? ref?.mobile_image?.alt ?? '',
  }
}

export const parseTextRef = (input: SbText[] | SbText | undefined) => {
  const ref = Array.isArray(input) ? input[0] : input

  if (
    !ref ||
    !ref?.body?.content?.some((block) => {
      return block.type !== 'paragraph' || Array.isArray(block.content)
    })
  ) {
    return undefined
  }

  return ref
}

export const getLinkRef = (input: SbLink[] | SbLink | undefined) => {
  const ref = Array.isArray(input) ? input[0] : input
  if (isValidLink(ref?.cta_url)) {
    return ref
  }
}
