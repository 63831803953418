<template>
  <Transition
    v-bind="activeClasses"
    enter-from-class="translate-y-2 opacity-0"
    enter-to-class="opacity-100 translate-y-0"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-2"
    :appear="appear"
  >
    <slot />
  </Transition>
</template>

<script setup lang="ts">
const props = defineProps(transitionsProps)
const { activeClasses } = useTransitions(props.duration)
</script>
