import type { CookieOptions, CookieRef } from 'nuxt/app'

export const useJsonCookie = <T = ReturnType<typeof JSON.parse>>(
  name: string,
  options: Omit<CookieOptions<T>, 'encode' | 'decode'> = {},
): CookieRef<T> => {
  return useCookie<T>(name, {
    ...options,
    // @ts-expect-error unknown cannot be assiged to T
    decode(value) {
      try {
        return JSON.parse(value)
      } catch {
        return undefined
      }
    },
    encode(value) {
      return JSON.stringify(value)
    },
    readonly: false,
  })
}
