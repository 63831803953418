import type { Variant } from '@scayle/storefront-nuxt'

const useVTOEvents = (
  track: (
    event: FimTrackingEvent,
    payload: FimTrackingPayload,
    options?: MapToTrackingPayloadOptions,
  ) => any,
) => {
  return {
    trackVTOFittingBox: ({
      productDetails,
      variant,
      element,
    }: FimVtoTrackingPayload & { variant: Variant }) => {
      console.log('trackVTOFittingBox called ****************************')

      track(
        'FielmannBasic_VirtualTryOn',
        { productDetails, element },
        { variant },
      )
    },
  }
}

export default useVTOEvents
