import type {
  RpcContext,
  // type Product,
} from '@scayle/storefront-nuxt'
import { traverse } from 'async-traverse-tree'
import type { ISbResult } from 'storyblok-js-client'
import type {
  SbLinkObject,
  SbMetaContent,
  SbMultilinkObject,
  SbRichTextLink,
} from '../storyblok/types/storyblok'
import type { ObjectWith } from './types'
import { isFimObject } from './typeguards'
import { getNormalizedLocale } from './locale'
import { trailingSlash } from './route'
import { metaTagGenerator } from './seo'
import type { LocationQuery } from '#vue-router'

const join = (arr: Array<string | undefined>) =>
  arr
    .map((folder) => {
      if (typeof folder === 'string') {
        return folder.replace(/(^\/+)|(\/+$)/g, '')
      }
      return undefined
    })
    .filter(Boolean)
    .join('/')
/**
 * get normalized country based storyblok base folder without trailing or leading slashes
 * @param $currentShop
 * @param baseFolder
 * @returns
 */
export const getLocalizedBaseFolder = (
  $currentShop: ObjectWith<{ cmsBaseFolder: string }>,
  baseFolder?: string,
) => {
  return join([$currentShop?.cmsBaseFolder, baseFolder])
}

export const getLocalizedStoryPath = (
  $currentShop: ObjectWith<{ cmsBaseFolder: string }>,
  slug: string,
  baseFolder?: string,
) => {
  return join([getLocalizedBaseFolder($currentShop, baseFolder), slug])
}

export const getRedirectToFromTranslatedSlug = (
  slug:
    | {
        lang: string
        path: string
      }
    | undefined,
) => {
  if (!slug?.lang || !slug?.path) {
    return
  }
  const parts = slug.path.split('/')
  if (Array.isArray(parts)) {
    return parts.slice(2).join('/')
  }
}

export const normalizeFullSlug = (fullSlug: string) => {
  return fullSlug.replace(/^.*\/content\//, '/').replace(/([^/])$/, '$1/')
}

const hasFullSlug = (value: any): value is ObjectWith<{ full_slug: string }> =>
  isFimObject(value) && typeof value.full_slug === 'string'

// Reserved component names in Vue are e.g.: Image, Video, Text
const isReservedComponentName = (key: string, value: any): value is string =>
  key === 'component' &&
  typeof value === 'string' &&
  ['Button', 'Image', 'Video', 'Text', 'Link'].includes(value)

const isSbRichTextLink = (value: any): value is SbRichTextLink =>
  value?.type === 'link' && value?.attrs?.uuid

const isSbMultilink = (value: any): value is SbMultilinkObject => {
  return (
    typeof value === 'object' &&
    value !== null &&
    value.fieldtype === 'multilink'
  )
}

export const processStoryblokResult = async (
  input: ISbResult['data'],
  context: RpcContext,
): Promise<ISbResult['data']> => {
  const links: SbLinkObject[] | undefined = input?.links
  // use country specific language if available
  const language = getNormalizedLocale(context.locale).toLowerCase()

  return await traverse<ISbResult['data'], ISbResult['data']>(
    input,
    async (key, value) => {
      // resolve links generated by the translatable slugs plugin
      if (isSbMultilink(value)) {
        const link = links?.find((link) => link.uuid === value.id)

        if (
          (value.linktype === 'story' && (!link || !value.id)) ||
          (value.linktype === 'url' && !value.url)
        ) {
          return undefined
        }

        const alternate = link?.alternates?.find(
          (link) => link.lang === language,
        )
        if (alternate) {
          const url = await resolveUrl(
            normalizeFullSlug(alternate.path),
            context,
          )
          return {
            ...value,
            original_cached_url: value.cached_url,
            cached_url: trailingSlash(url),
            alternates: link?.alternates ?? [],
          }
        }

        const url = await resolveUrl(value.cached_url, context)
        return {
          ...value,
          original_cached_url: value.cached_url,
          cached_url: trailingSlash(url),
        }
      }

      if (isSbRichTextLink(value)) {
        const link = links?.find((link) => link.uuid === value.attrs.uuid)
        const alternate = link?.alternates?.find(
          (link) => link.lang === language,
        )
        if (alternate) {
          const url = await resolveUrl(
            normalizeFullSlug(alternate.path),
            context,
          )
          return {
            ...value,
            attrs: {
              ...value.attrs,
              href: trailingSlash(url),
              original_href: value.attrs.href,
            },
          }
        }

        const url = await resolveUrl(value.attrs.href, context)
        return {
          ...value,
          attrs: {
            ...value.attrs,
            href: trailingSlash(url),
            original_href: value.attrs.href,
          },
        }
      }

      if (key === 'cta_url' && typeof value === 'string') {
        const url = await resolveUrl(value, context)
        return trailingSlash(url)
      }

      // Image is a reserved component name in Vue, so we use the CmsImage component name
      if (typeof key === 'string' && isReservedComponentName(key, value)) {
        return `Cms${value}`
      }

      // normalize full_slug
      if (hasFullSlug(value)) {
        return {
          ...value,
          full_slug: trailingSlash(normalizeFullSlug(value.full_slug)),
        }
      }

      return value
    },
  )
}

const resolveUrl = async (url: string, context: RpcContext) => {
  const idMatch = url.match(/\/(categories|products)\/(\d+)/)
  if (idMatch) {
    const [, type, _id] = idMatch
    const id = parseInt(_id, 10)

    switch (type) {
      case 'categories':
        try {
          const category = await context.bapiClient.categories.getById(id)
          return category.path
        } catch (e) {
          return `/${id}/`
        }
      case 'products':
        return `/p/${id}/`
    }
  }

  const pathMatch = url.match(/\/(product-categories)\/(.*)/)
  if (pathMatch) {
    const [, type, path] = pathMatch

    try {
      // eslint-disable-next-line sonarjs/no-small-switch
      switch (type) {
        case 'product-categories': {
          const category = await context.bapiClient.categories.getByPath([
            path.replace(/\/$/, ''),
          ])
          return category.path
        }
      }
    } catch (e) {
      return url.replace(/.*\/product-categories\//, '/')
    }
  }

  if (
    url.startsWith('/') ||
    url.startsWith('#') ||
    url.startsWith('?') ||
    url.includes('://')
  ) {
    return url
  }

  return '/' + url
}

export const getIsInEditorMode = (query: LocationQuery) => '_storyblok' in query

export const getCmsMeta = (metaContent?: SbMetaContent) => {
  const robots = metaContent?.robots
  const seoContent = metaContent?.SEO
  const metaTitle = seoContent?.title
  const metaTags = metaTagGenerator({
    description: seoContent?.description,
    robots: robots || 'index,follow',
    fimSocialCards: {
      twitterCard: seoContent?.twitter_description
        ? seoContent?.twitter_description
        : seoContent?.description,
      twitterTitle: seoContent?.twitter_title
        ? seoContent?.twitter_title
        : seoContent?.title,
      twitterImage: seoContent?.twitter_image,
      ogTitle: seoContent?.og_title ? seoContent?.og_title : seoContent?.title,
      ogDescription: seoContent?.og_description
        ? seoContent?.og_description
        : seoContent?.description,
      ogImage: seoContent?.og_image,
    },
  })

  return {
    ...(metaTitle ? { title: metaTitle } : {}),
    ...metaTags,
  }
}

export type CmsMeta = Partial<ReturnType<typeof getCmsMeta>>
