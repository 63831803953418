import { getEmailHash } from '~/composables/tracking/helpers'

const useCustomerEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
) => ({
  trackCustomerData: async (payload: CustomerData) => {
    track('customer_data', {
      ...payload,
      eh: payload.eh ? await getEmailHash(payload.eh) : '',
    })
  },
})

export default useCustomerEvents
