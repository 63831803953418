export const BasketListingMetadata = {
  ID: 'BL',
  NAME: 'Basket List',
} as const

export const WishlistListingMetadata = {
  ID: 'WL',
  NAME: 'Wishlist List',
} as const

export const CategoryListingMetadata = {
  ID: 'CategoryProductList',
  NAME: 'Category Product List',
} as const


// A list showing specific item categories (glasses, sunglasses etc.)
export const CategoryListingMetadataType = 'Category List'

// A list showing only brand specific items (ray ban, gucci, fielmann etc.)
export const BrandListingMetadataType = 'Brand List'

// A list showing search results on /search/?[term] after a user searches any term that shows items
export const SearchResultListingMetadataType = 'Search Result List'

// A list showing items recommended to the user
export const RecommendationListingMetadataType = 'Recommendation List'

// A list that teases a small group of products (homepage slider list etc.)
export const TeaserListingMetadataType = 'Teaser List'

