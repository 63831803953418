import {
  type WishlistItem,
  getLowestPrice,
  type Product,
  type Variant,
} from '@scayle/storefront-nuxt'

const useWishlistEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
) => ({
  trackWishlistItems: (items: WishlistItem[] = []) => {
    const wishlistPayload: WishlistData = {
      items: items || [],
      total_campaign_reduction_with_tax: 0.0,
      total_sale_reduction_with_tax: 0.0,
      total_with_tax: 0.0,
      total_without_tax: 0.0,
    }

    items.forEach((wishlistItem) => {
      wishlistItem.variant?.price.appliedReductions
        .filter((reduction) => reduction.category === 'sale')
        .forEach(
          (reduction) =>
            (wishlistPayload.total_sale_reduction_with_tax +=
              reduction.amount.absoluteWithTax),
        )
      wishlistItem.variant?.price.appliedReductions
        .filter((reduction) => reduction.category === 'campaign')
        .forEach(
          (reduction) =>
            (wishlistPayload.total_campaign_reduction_with_tax +=
              reduction.amount.absoluteWithTax),
        )

      if (wishlistItem.variant) {
        wishlistPayload.total_with_tax +=
          wishlistItem.variant?.price.withTax || 0.0
        wishlistPayload.total_without_tax +=
          wishlistItem.variant?.price.withoutTax || 0.0
      } else if (wishlistItem.product?.variants) {
        const { withTax = 0.0, withoutTax = 0.0 } = getLowestPrice(
          wishlistItem.product.variants,
        )
        wishlistPayload.total_with_tax += withTax
        wishlistPayload.total_without_tax += withoutTax
      }
    })

    track('wishlist', wishlistPayload)
  },
  trackRemoveFromWishlist: (product: Product, variant?: Variant) => {
    const payload: ProductActionData = {
      product,
    }
    if (variant) {
      payload.variant = variant
    }

    track('FielmannBasic_EC_RemoveFromWishlist', payload)
  },
  trackAddToWishlist: (product: Product, variant?: Variant) => {
    track('FielmannBasic_EC_AddToWishlist', { product, variant })
  },
})
export default useWishlistEvents
