<template>
  <div v-if="price">
    <slot
      :price="price"
      :is-free="isFree"
      :applied-reductions="appliedReductions"
      :total-reductions="totalReductions"
      :to-currency="toCurrency"
      :total-price="totalPrice"
      :classes="priceWrapperClass"
      :is-automatic-discount-price-applicable="
        isAutomaticDiscountPriceApplicable
      "
    >
      <slot name="relative-reductions">
        <span
          v-if="showBadge && totalReductions"
          class="inline-block rounded-sm bg-red-500 px-2 py-1 text-sm text-white"
        >
          -{{ totalReductions.relative * 100 }}%
        </span>
      </slot>
      <p v-if="zeroCostProduct" :class="priceWrapperClass">
        {{ $t('zero_cost_frames.price_replacement') }}

        <I18nT
          v-if="zeroCostInfoSlug"
          class="font-normal"
          keypath="zero_cost_frames.price_replacement_link_addon"
          tag="span"
        >
          <template #link>
            <FimLink
              :to="zeroCostInfoSlug"
              :only-exact-active="true"
              target="_blank"
              type="underline"
              text-size="inherit"
            >
              {{ $t('zero_cost_frames.price_link_text') }}
            </FimLink>
          </template>
        </I18nT>
        <span v-else class="font-normal">{{
          $t('zero_cost_frames.price_replacement_addon')
        }}</span>
      </p>
      <p v-else class="flex items-end" :class="priceWrapperClass">
        <span v-if="showPriceFrom" class="mr-1" :class="startingFromClass">{{
          $t('price.starting_from')
        }}</span>

        <span :class="actualPriceClass">{{ totalPrice }}</span>

        <span
          v-if="totalReductions.absoluteWithTax && quantity !== 0"
          class="text-black line-through"
          :class="priceWithoutReductionClass"
          >{{
            toCurrency(
              (price.withTax + totalReductions.absoluteWithTax) * quantity,
            )
          }}</span
        >

        <slot name="free-product">
          <span
            v-if="!zeroCostProduct && textWhenFree && price.withTax <= 0"
            class="ml-3 text-base font-normal"
          >
            {{ textWhenFree }}
          </span>
        </slot>
      </p>

      <p
        v-if="totalReductions.absoluteWithTax && showDiscount && quantity !== 0"
        class="text-base font-medium text-pricing"
      >
        {{ $t('price.you_save') }}
        {{ toCurrency(quantity * totalReductions.absoluteWithTax) }}
      </p>
      <p
        v-if="
          totalReductions.absoluteWithTax &&
          showRelativeDiscount &&
          quantity !== 0
        "
        class="text-base font-medium text-pricing"
      >
        {{ $t('price.you_save') }}
        {{ totalReductions.relative * 100 }}%
      </p>

      <slot name="tax-info">
        <span
          v-if="showTaxInfo && price.withTax > 0"
          class="text-sm text-gray-700"
        >
          {{ $t('price.including_vat') }}
        </span>
      </slot>
    </slot>
  </div>
</template>

<script setup lang="ts">
import {
  getTotalAppliedReductions,
  type Price,
  type AppliedReduction,
  type Product,
} from '@scayle/storefront-nuxt'

import type { VariantPrice } from '@scayle/storefront-api'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: false,
    default: () => undefined,
  },
  appliedReductions: {
    type: Array as PropType<AppliedReduction[]>,
    required: false,
    default: () => [],
  },
  price: {
    type: Object as PropType<Price | VariantPrice>,
    required: true,
    default: () => {},
  },
  showTaxInfo: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showPriceFrom: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isFree: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showPriceReductionBadge: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  quantity: {
    type: Number as PropType<number>,
    default: 1,
  },
  textWhenFree: {
    type: String as PropType<string>,
    default: '',
  },
  zeroCostProduct: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  zeroCostInfoSlug: {
    type: String as PropType<string>,
    default: '',
  },
  size: {
    type: String as PropType<'2xl' | 'xl' | 'lg' | 'md' | 'sm' | undefined>,
    default: undefined,
  },
  type: {
    type: String as PropType<'loud' | 'whisper'>,
    default: 'loud',
  },
  align: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },
  noMargin: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  breakLine: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showDiscount: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showRelativeDiscount: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showAutomaticDiscount: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { automaticDiscountPromotion, getAppliedAutomaticDiscountPrice } =
  await useProductPromotions(props.product)

const { data: basketData } = await useBasket()

const isAutomaticDiscountApplied = computed(() => {
  if (!automaticDiscountPromotion.value || !props.product) {
    return false
  }

  return basketData.value?.items.some(({ promotion, promotionId, product }) => {
    return (
      props.product?.id === product.id &&
      promotionId === automaticDiscountPromotion.value?.id &&
      promotion?.isValid
    )
  })
})

const isAutomaticDiscountPriceApplicable = computed(() => {
  return props.showAutomaticDiscount && isAutomaticDiscountApplied.value
})

const totalReductions = computed<AppliedReduction['amount']>(() => {
  if (props?.price?.appliedReductions?.length) {
    return getTotalAppliedReductions(props.price) as AppliedReduction['amount']
  } else {
    return {
      relative: 0,
      absoluteWithTax: 0,
    } as AppliedReduction['amount']
  }
})

const totalPrice = computed(() =>
  toCurrency((props?.price?.withTax ?? 0) * (props?.quantity ?? 0)),
)

const showBadge = computed(
  () => props.appliedReductions && props.showPriceReductionBadge,
)

const priceWrapperClass = computed(() => ({
  'mt-2': showBadge.value,
  'text-2xl': props.size === '2xl',
  'text-xl': props.size === 'xl',
  'text-lg': props.size === 'lg',
  'text-base': props.size === 'md',
  'text-sm': props.size === 'sm',
  'font-medium': props.type === 'loud',
  'font-normal': props.type !== 'loud',
  'text-pricing': totalReductions.value.absoluteWithTax && props.quantity !== 0,
  'justify-start': props.align === 'left',
  'justify-end text-right': props.align === 'right',
  'flex flex-col leading-3': props.breakLine,
  'leading-snug': !props.breakLine,
}))

const startingFromClass = computed(() => ({
  'text-2xl': props.size === '2xl',
  'text-xl': props.size === 'xl',
  'text-lg': props.size === 'lg',
  'text-base leading-[22px]': props.size === 'md',
  'text-sm': props.size === 'sm',
}))

const actualPriceClass = computed(() => ({
  'text-2xl': props.size === '2xl',
  'text-xl': props.size === 'xl',
  'text-lg': props.size === 'lg',
  'text-base leading-[22px]': props.size === 'md',
  'text-sm': props.size === 'sm',
  'ml-1': !props.noMargin,
  'text-pricing': totalReductions.value.absoluteWithTax && props.quantity !== 0,
}))

const priceWithoutReductionClass = computed(() => [
  props.breakLine
    ? 'text-xs font-normal'
    : {
        'text-xl': props.size && ['2xl', 'xl'].includes(props.size),
        'text-lg': props.size === 'lg',
        'text-base leading-[22px]': props.size === 'md',
        'text-sm': props.size === 'sm',
        'font-medium': true,
        'ml-2.5': true,
      },
])
</script>
