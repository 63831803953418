<template>
  <component
    :is="tag"
    :to="tag === NuxtLink ? to : undefined"
    :href="tag === 'a' ? to : undefined"
    :rel="tag === 'a' ? rel : null"
    :aria-label="ariaLabelComputed"
    :data-camera-button-placement="isImageSearch ? 'tooltip' : undefined"
    :data-tracking-label="
      isImageSearch ? 'Image Search | tooltip' : dataTrackingLabel
    "
    :data-tracking-id="dataTrackingId"
    tabindex="0"
    class="group inline-flex cursor-pointer items-center gap-2 whitespace-nowrap text-lg font-medium leading-[1.375rem] tracking-normal outline-offset-4 outline-button-outline transition duration-100 ease-linear disabled:pointer-events-none disabled:opacity-25"
    :target="target"
    :class="{
      'justify-center': justify === 'center',
      'justify-start': justify === 'start',
      'justify-end': justify === 'end',
      'border-2': !isRaw,
      'rounded-full border-button-primary-bg bg-button-primary-bg text-white hover:border-button-primary-hover hover:bg-button-primary-hover active:border-button-primary-active active:bg-button-primary-active':
        isPrimary && isDarkButton,
      'rounded-full border-button-primary-light-bg bg-button-primary-light-bg text-button-primary-light-text hover:border-button-primary-light-hover hover:bg-button-primary-light-hover active:border-button-primary-light-active active:bg-button-primary-light-active':
        isPrimary && isLightButton,
      'rounded-full border-button-secondary-border bg-button-secondary-bg text-button-secondary-border hover:border-button-secondary-border-hover hover:bg-button-secondary-hover hover:text-button-secondary-border-hover active:border-button-secondary-border-active active:bg-button-secondary-active active:text-button-secondary-border-active':
        isSecondary && isDarkButton,
      'rounded-full border-button-secondary-light-border bg-button-secondary-light-bg text-button-secondary-light-border hover:border-button-secondary-light-border-hover hover:bg-button-secondary-light-hover hover:text-button-secondary-light-border-hover active:border-button-secondary-light-border-active active:bg-button-secondary-light-active active:text-button-secondary-light-border-active':
        isSecondary && isLightButton,
      'rounded-full border-transparent text-button-tertiary-text hover:border-button-tertiary-hover hover:bg-button-tertiary-hover hover:text-button-tertiary-text-hover active:border-button-tertiary-hover active:bg-button-tertiary-hover active:text-button-tertiary-text-active':
        isTertiary,
      'type-underline rounded-lg border-transparent text-link-color hover:text-link-hover active:text-link-active':
        isTypeLink || isTypeLinkAlternative,
      'text-md font-normal': isTypeLinkAlternative,
      'w-full': isFullWidth,
      'animate-pulse': loading,
      'p-4': showAsIconButton,
      'px-8 py-4': !isSmall && !noPadding && !showAsIconButton,
      'px-4 py-1': isSmall && !noPadding && !showAsIconButton,
      'h-10 max-w-[136px] !border-none !p-0': isStoreButton,
      '--syte-start-camera-upload': isImageSearch,
    }"
    :disabled="disabled"
    :type="htmlType"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @mousedown="$emit('mousedown', $event)"
  >
    <template v-if="showAsIconButton">
      <LazyIconFielmannArrowRight class="size-6" />
    </template>
    <template v-else-if="storeIconSrc">
      <NuxtImg
        provider="local"
        :src="storeIconSrc"
        alt=""
        loading="lazy"
        decoding="async"
        class="h-10"
      />
      <span class="sr-only"><slot /></span>
    </template>
    <template v-else>
      <slot name="icon" :class="iconClasses" />
      <span
        v-if="hasDefaultSlot"
        ref="buttonLabel"
        class="content pointer-events-none"
        :class="{ 'w-full text-center': isFullWidth }"
      >
        <slot />
      </span>

      <slot name="iconAfter" :class="iconClasses" />
      <slot name="badge" :badge="badge">
        <transition
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          enter-active-class="transition ease-linear duration-200"
          leave-active-class="transition ease-linear duration-200"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <span v-if="badge" :class="textColorClasses"> ({{ badge }}) </span>
        </transition>
      </slot>
    </template>
  </component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'
import type { ButtonType } from '#imports'

type Props = {
  type?: ButtonType
  htmlType?: 'submit' | 'reset' | 'button'
  noPadding?: boolean
  to?: string | object
  disabled?: boolean
  isFullWidth?: boolean // TODO refactor use class instead
  loading?: boolean
  badge?: number // TODO Refactor maybe it's not used anymore
  isSmall?: boolean
  target?: '_blank' | '_self' | '_top' | '_parent'
  ariaLabel?: string
  justify?: 'start' | 'center' | 'end' // TODO refactor use class instead
  rel?: HTMLAnchorElement['rel']
  theme?: 'light' | 'dark'
  showAsIconButton?: boolean
  dataTrackingLabel?: string
  dataTrackingId?: string
}

defineEmits(['mouseenter', 'mouseleave', 'mousedown'])

const props = withDefaults(defineProps<Props>(), {
  type: 'primary',
  htmlType: undefined,
  to: undefined,
  noPadding: false,
  disabled: false,
  isFullWidth: false,
  loading: false,
  badge: 0,
  isSmall: false,
  justify: 'center',
  target: undefined,
  ariaLabel: undefined,
  rel: undefined,
  theme: 'light',
  showAsIconButton: false,
  dataTrackingLabel: undefined,
  dataTrackingId: undefined,
})

const slots = useSlots()
const currentShop = useCurrentShop()

const isImageSearch = computed(() => props.type === 'image-search')
const isPrimary = computed(() =>
  ['primary', 'image-search'].includes(props.type),
)
const isSecondary = computed(() => props.type === 'secondary')
const isTertiary = computed(() => props.type === 'tertiary')
const isRaw = computed(() => props.type === 'raw')

const isTypeLink = computed(() => props.type === 'link')
const isTypeLinkAlternative = computed(() => props.type === 'link-alternative')

const iconClasses = computed(() => {
  return {
    'text-neutral-50': isSecondary.value || isTypeLink.value,
  }
})

const tag = computed(() => {
  if (isImageSearch.value) {
    return 'button'
  } else if (
    typeof props.to === 'string' &&
    (/:\/\//.test(props.to) || /mailto:/.test(props.to))
  ) {
    return 'a'
  } else if (props.to) {
    return NuxtLink
  }
  return 'button'
})

const textColorClasses = computed(() => ({
  'text-white': isPrimary.value,
  'text-primary-100': isSecondary.value,
  'text-primary': isTertiary.value,
}))
const hasDefaultSlot = computed(() => !!slots.default)

const buttonLabel = ref<HTMLSpanElement>()

const ariaLabelComputed = computed(
  () => props.ariaLabel ?? buttonLabel.value?.innerText ?? '',
)

const isStoreButton = computed(() =>
  ['play-store', 'app-store'].includes(props.type),
)
const storeIconSrc = computed(() => {
  const lang = getIsoLanguage(currentShop.value.locale)
  if (isStoreButton.value) {
    return `/img/store-buttons/${props.type}-${lang}.svg`
  }

  return undefined
})

const isDarkButton = computed(
  () => props.theme === undefined || props.theme === 'light',
)
const isLightButton = computed(() => props.theme === 'dark')

defineOptions({ name: 'FimButton' })
</script>
