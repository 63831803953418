import type { RouteMeta } from '#vue-router'
import localeDomains from '~/config/domains'

export type PageState = {
  pageType: PageType
  plpType: PlpPageType | undefined
  productCategory: string
  userEmail: string
  lastCategoryRoute: RouteMeta
  localeDomains: typeof localeDomains
  deviceType: string
  breakpoint: string
  isBotTraffic: boolean
}

export default () => {
  const state = useState<PageState>('page-type-state', () => ({
    pageType: '',
    plpType: undefined,
    productCategory: '',
    userEmail: '',
    lastCategoryRoute: {},
    localeDomains,
    deviceType: '',
    breakpoint: '',
    isBotTraffic: false,
  }))

  const setPageState = (
    key: keyof PageState,
    value: PageState[keyof PageState],
  ) => {
    state.value = { ...state.value, [key]: value }
  }

  const pageState = computed(() => state.value)

  return {
    setPageState,
    pageState,
  }
}
