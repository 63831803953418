import type { NuxtError } from '#app'
import axios from 'axios'

const NUXT_ERROR_SIGNATURE = '__nuxt_error'

const isNuxtError = (error: any): error is NuxtError =>
  !!error && typeof error === 'object' && NUXT_ERROR_SIGNATURE in error

export const getErrorMessage = (
  error: string | Error | NuxtError | unknown,
) => {
  if (typeof error === 'string') {
    return error
  }

  if (isNuxtError(error)) {
    return error.message
  }

  if (axios.isAxiosError(error)) {
    return error.message
  }

  if (error instanceof Error) {
    return error.message
  }

  return ''
}

export const getErrorExtras = (
  error: string | Error | NuxtError | unknown,
  extras: Parameters<typeof JSON.stringify>['0'],
) => {
  const res = typeof extras === 'string' ? { extras } : { ...extras }

  if (isNuxtError(error!)) {
    return {
      ...res,
      data: error.data,
      statusCode: error.statusCode,
      statusMessage: error.statusMessage,
      fatal: error.fatal,
      unhandled: error.unhandled,
    }
  }

  if (axios.isAxiosError(error)) {
    return {
      ...res,
      data: error.response?.data,
      statusCode: error.response?.status,
      statusMessage: error.response?.statusText,
      code: error.code,
    }
  }

  if (error instanceof Error) {
    return {
      ...res,
      name: error.name,
      stack: error.stack,
      cause: error.cause,
    }
  }

  return Object.keys(res).length ? { ...res } : undefined
}
