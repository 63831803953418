export const useGlassesLenses = () => {
  const promise = useRxProducts()
  const { rxLensQualityIndex, fetch } = promise

  const lensesVariants = computed(() => {
    return (
      rxLensQualityIndex.value?.map((i) => {
        const variant = i.variants || []
        return variant[0]
      }) || []
    )
  })

  return extendPromise(
    promise.then(() => ({})),
    {
      fetch,
      lensesVariants,
      lensesProducts: rxLensQualityIndex,
    },
  )
}
