import type {
  BasketItemDisplayData,
  BaskteItemDisplayDataItem,
  RpcContext,
  Variant,
} from '@scayle/storefront-nuxt'
import type { VariantDetail } from '@scayle/storefront-api'
import type { NuxtApp } from 'nuxt/app'
import {
  QUANTITY_LEFT_KEY_NAME,
  QUANTITY_RIGHT_KEY_NAME,
  VARIANT_ID_LEFT_KEY_NAME,
  VARIANT_ID_RIGHT_KEY_NAME,
} from '../constants/lens'
import {
  type FormattableLensesPrescriptionProp,
  formatPrescriptionValue,
} from './rx'
import type { ObjectWith } from './types'
import type {
  CombinedDeliveryEstimate,
  DeliveryForecastAttribute,
} from '~/composables/pdp/useLenses'
import type { ContactLensValuesMapped } from '~/rpcMethods'
import type { FimBasketItemCustomData } from '~/composables/useFimBasket'

export const LENS_PROP_DEFAULT_VALUE = '--'

const STRING_FORMATTED_NUMBERS = ['sphere', 'cylinder'] as const
const NUMERIC_PROPS = ['axis', 'baseCurve', 'diameter'] as const
const STRING_PROPS = ['addition', 'contactLensColor'] as const
export const contactLensProps = [
  ...STRING_FORMATTED_NUMBERS,
  ...NUMERIC_PROPS,
  ...STRING_PROPS,
] as const

type ContactLensNumericProp = (typeof NUMERIC_PROPS)[number]
export type ContactLensStringNumberProp =
  (typeof STRING_FORMATTED_NUMBERS)[number]
type ContactLensStringProp = (typeof STRING_PROPS)[number]
export type ContactLensProp = (typeof contactLensProps)[number]

export type ContactLensValue = Partial<
  Record<ContactLensNumericProp, number> &
    Record<ContactLensStringProp, string> &
    Record<ContactLensStringNumberProp, string | number>
>

export type ContactLensOptions = Partial<
  Record<ContactLensNumericProp, number[]> &
    Record<ContactLensStringProp, string[]> &
    Record<ContactLensStringNumberProp, string[]>
>

export type ContactLensDisplayValue = Partial<
  Record<keyof ContactLensValue, string>
>

export type ContactLensDisplayValues = {
  left?: ContactLensDisplayValue
  right?: ContactLensDisplayValue
}

export type ContactLensValues = {
  left: ContactLensValue
  right: ContactLensValue
}

export type EyeVariantIds = {
  left: string | undefined
  right: string | undefined
}

export type EyePositionIndex = 'left' | 'right' | 'both'

export type FimBasketItemDisplayData = BasketItemDisplayData & {
  'attribute-4'?: BaskteItemDisplayDataItem
}

export enum ContactLensDisplayDataAttributes {
  numberOfLensesAttribute = 'attribute-1',
  eyeTypeAttribute = 'attribute-2',
  prescriptionValuesAttribute = 'attribute-3',
  deliveryForecastAttribute = 'attribute-4',
}

export const isContactLensProp = (prop: any): prop is ContactLensProp => {
  return contactLensProps.includes(prop)
}

const isContactLensFormattedNumber = (
  prop: any,
): prop is ContactLensStringNumberProp => {
  return contactLensProps.includes(prop)
}

const toFloat = (numberString: string): number =>
  parseFloat(numberString.replace(',', '.'))

const formatAdditionValue = (
  value: string,
  currentShop: NuxtApp['$currentShop'] | RpcContext,
): string => {
  const matches = value.match(/(\w+) \((\d+(?:[,.]\d+)?) - (\d+(?:[,.]\d+)?)\)/)
  if (!matches || matches.length !== 4) {
    return value
  }
  const numbers = matches.slice(2, 4)
  const numbersFormatted = numbers.map((number) =>
    formatPrescriptionValue('addition', toFloat(number), currentShop, false),
  )
  return `${matches[1]} (${numbersFormatted[0]} - ${numbersFormatted[1]})`
}

export const parseFormattedValue = (value: string): number =>
  toFloat(value.replace(/(\+-)|±/, ''))

export const getFormattedLensValue = (
  prop: ContactLensProp,
  value: string | number | undefined,
  currentShop: NuxtApp['$currentShop'] | RpcContext,
  showUnit = false,
) => {
  if (value === undefined) {
    return '-'
  }
  const isFormattable = prop !== 'contactLensColor'
  const isAddition = prop === 'addition'
  const isFormattedNumber = isContactLensFormattedNumber(prop)
  const isString = typeof value === 'string'
  let formattedValue = value
  if (isFormattable) {
    if (isAddition && isString) {
      formattedValue = formatAdditionValue(value.toString(), currentShop)
    } else {
      let usePlusMinusSign = false
      if (isFormattedNumber && isString) {
        formattedValue = parseFormattedValue(value.toString())
        usePlusMinusSign = formattedValue === 0
      }

      formattedValue = formatPrescriptionValue(
        prop as FormattableLensesPrescriptionProp,
        formattedValue as number,
        currentShop,
        showUnit,
      )

      if (usePlusMinusSign) {
        formattedValue = `±${formattedValue}`
      }
    }
  }
  return formattedValue.toString()
}

export const eyeInputFullySelected = (eye: ContactLensValue) => {
  const values = Object.values(eye)
  return (
    values.length > 0 &&
    values.every(
      (value) => value !== undefined && value !== LENS_PROP_DEFAULT_VALUE,
    )
  )
}

export const getSightDataDisplayValue = (
  eyeData: Partial<ContactLensValue>,
  i18n: NuxtApp['$i18n'],
  currentShop: NuxtApp['$currentShop'],
) => {
  const sightDataString = contactLensProps
    .map((key) => {
      const value = eyeData[key as keyof ContactLensValue]
      if (value) {
        return (
          i18n.t(`pdp.lenses.${key}`) +
          ' ' +
          getFormattedLensValue(key as ContactLensProp, value, currentShop)
        )
      }
      return null
    })
    .filter(Boolean)
    .join(' | ')

  return {
    key: 'prescriptionValues',
    label: i18n.t('cart.lens_details'),
    value: sightDataString,
  }
}

export const getContactLensDisplayData = (
  i18n: NuxtApp['$i18n'],
  $currentShop: NuxtApp['$currentShop'],
  eyeValues?: Partial<ContactLensValues>,
  eyePositionIndex?: EyePositionIndex,
  deliveryForecast?: DeliveryForecastAttribute,
  shouldUseDeliveryForecast?: boolean,
  numberOfLenses?: string,
): FimBasketItemDisplayData => {
  const displayData: FimBasketItemDisplayData = {}
  const eyeDataPositionIndex =
    eyePositionIndex === 'both' ? 'left' : eyePositionIndex

  if (eyeDataPositionIndex && eyeValues && eyeDataPositionIndex in eyeValues) {
    displayData[ContactLensDisplayDataAttributes.prescriptionValuesAttribute] =
      getSightDataDisplayValue(
        eyeValues[eyeDataPositionIndex]!,
        i18n,
        $currentShop,
      )
    displayData[ContactLensDisplayDataAttributes.eyeTypeAttribute] = {
      key: 'eyeType',
      label: i18n.t('pdp.eye'),
      value: i18n.t(`basket.lenses.${eyePositionIndex}`),
    }
  }

  if (numberOfLenses) {
    displayData[ContactLensDisplayDataAttributes.numberOfLensesAttribute] = {
      key: 'numberOfLenses',
      label: i18n.t('pdp.number_of_lenses'),
      value: numberOfLenses,
    }
  }

  if (!!deliveryForecast?.key && shouldUseDeliveryForecast) {
    displayData[ContactLensDisplayDataAttributes.deliveryForecastAttribute] =
      deliveryForecast
  } else if (shouldUseDeliveryForecast) {
    displayData[ContactLensDisplayDataAttributes.deliveryForecastAttribute] = {
      key: i18n.t('basket.displayData.deliveryForecastFallback.key'),
      label: i18n.t('basket.displayData.deliveryForecastFallback.label'),
      value: i18n.t('basket.displayData.deliveryForecastFallback.value'),
    }
  }

  return displayData
}

export const getContactLensDisplayDataForBasket = (
  eyeData: ContactLensValues,
  eyePositionIndex: EyePositionIndex,
  i18n: NuxtApp['$i18n'],
  $currentShop: NuxtApp['$currentShop'],
  numberOfLenses?: string,
  deliveryForecastValues?: CombinedDeliveryEstimate,
): FimBasketItemDisplayData => {
  const shouldUseDeliveryForecast =
    deliveryForecastValues?.isSellableWithoutStock &&
    deliveryForecastValues?.stockQuantity === 0
  return getContactLensDisplayData(
    i18n,
    $currentShop,
    eyeData,
    eyePositionIndex,
    deliveryForecastValues?.deliveryForecast,
    shouldUseDeliveryForecast,
    numberOfLenses,
  )
}

export const getDefaultSelectData = (
  variantValues: ContactLensValuesMapped | null,
) => {
  return Object.keys(variantValues || []).reduce((acc, key) => {
    const identifier = variantValues?.[key as ContactLensProp]
    const value =
      identifier && identifier.length === 1
        ? identifier[0].key
        : LENS_PROP_DEFAULT_VALUE

    return Object.assign(acc, {
      [key]: value,
    })
  }, {} as ContactLensValue)
}

export const getContactLensValuesFromVariant = (
  variantDetail: VariantDetail | Variant,
) => {
  return Object.values(variantDetail.attributes ?? {}).reduce(
    (prevVal, attribute) => ({
      ...prevVal,
      ...(attribute &&
        contactLensProps.includes(attribute.key ?? '') && {
          [attribute.key]: Array.isArray(attribute.values)
            ? attribute.values[0].label
            : attribute.values.label,
        }),
    }),
    {} as ContactLensValue,
  )
}

export const getLensDetailsFromBasketItem = (
  customData: FimBasketItemCustomData,
  variant: ObjectWith<{ id: number }>,
) => ({
  // @ts-expect-error to avoid convoluted nested prop type narrowing
  [QUANTITY_LEFT_KEY_NAME]: customData.quantity?.left ?? 0,
  // @ts-expect-error to avoid convoluted nested prop type narrowing
  [QUANTITY_RIGHT_KEY_NAME]: customData.quantity?.right ?? 0,
  ...(customData.prescriptionValues?.left && {
    [VARIANT_ID_LEFT_KEY_NAME]: variant.id,
  }),
  ...(customData.prescriptionValues?.right && {
    [VARIANT_ID_RIGHT_KEY_NAME]: variant.id,
  }),
})
