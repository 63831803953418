import type { Locale, LocalePath } from '~/types/i18n'
import type { RouteLocation, RouteLocationRaw } from '#vue-router'

export default defineNuxtPlugin({
  name: 'fim-locale-path',
  dependsOn: ['i18n:plugin'],
  setup(nuxtApp) {
    return {
      provide: {
        fimLocalePath: (
          route: RouteLocation | RouteLocationRaw,
          locale?: Locale,
        ) => {
          const localePath = nuxtApp.$localePath as LocalePath

          if (typeof route === 'string' && /:\/\//.test(route)) {
            return route
          }

          const lp: string = localePath(route, locale)

          // eslint-disable-next-line prefer-const
          let [path, ...query] = lp?.split('?') ?? []
          path = path.replace(/(\/)*$/, '/')

          return [decodeURIComponent(path), ...query].join('?')
        },
      },
    }
  },
})
