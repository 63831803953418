import type { PublicShopConfig } from '@scayle/storefront-nuxt'

export const isFraud0Enabled = (currentShop: PublicShopConfig) =>
  Boolean(currentShop?.fraud0TagId)

export const fraud0tracking = (eventName: 'purchase', payload: string) => {
  if (typeof window === 'undefined') return

  // @ts-expect-error
  const fraud0 = window?.fraud0 || []
  fraud0.push([eventName, payload])
}
