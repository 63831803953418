<template>
  <NuxtLink
    :to="
      localePath(
        getProductDetailRoute(product, {
          variantid: variant.id,
          ...lensesDetails,
        }),
      )
    "
    class="flex w-96 gap-3 py-7 text-sm text-primary"
    @click="trackSelectItem(product)"
  >
    <div class="w-16">
      <ProductPromotionFreeGiftBadge
        v-if="isFreeGift"
        :style="promotionStyle"
        class="absolute bottom-2 left-2"
      />
      <ProductImage v-if="image" :image="image" :alt="title" sizes="sm:100vw" />
    </div>
    <div class="flex w-full flex-col gap-1">
      <Headline is-uppercase tag="h3" look="h5">
        {{ name }}
      </Headline>
      <Headline is-uppercase tag="h3" look="h5" weight="normal">
        {{ title }}
      </Headline>
      <div class="flex justify-between">
        <div class="flex w-full flex-col gap-1">
          <div
            v-if="product && isContactLensProduct"
            class="flex w-full flex-col gap-1"
          >
            <Headline tag="p" look="h5" weight="normal">
              {{ getFirstAttributeLabel(product.attributes, 'wearingTime') }}
            </Headline>
            <Headline
              v-if="contactLensesPackaging"
              tag="p"
              look="h5"
              weight="normal"
            >
              {{
                $t('basket.lenses.packaging', {
                  number: contactLensesPackaging,
                })
              }}
            </Headline>
          </div>
          <div
            v-if="displayData && !isContactLensProduct"
            class="flex flex-col gap-1"
          >
            <Headline
              v-for="(entry, key) in allowedDisplayData"
              :key="key"
              tag="p"
              look="h5"
              weight="normal"
              :class="{ 'text-neutral-50': entry.key === 'colors' }"
            >
              <span v-if="withLabel.includes(entry.key)">
                {{ entry.label }}:
              </span>
              {{ entry.value }}
            </Headline>
          </div>
          <Headline tag="p" look="h5" weight="normal">
            {{ $t('basket_card.quantity_label') }}: {{ quantity }}
          </Headline>
        </div>
        <div class="flex flex-col justify-end">
          <div class="flex gap-3">
            <ProductPrice
              size="md"
              :price="price"
              :quantity="quantity"
              :applied-reductions="appliedReductions"
            />
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import {
  getAppliedReductionsByCategory,
  type Price as BapiPrice,
  type Product,
  type ProductImage as ProductImageType,
  type Variant,
} from '@scayle/storefront-nuxt'
import { DisplayDataAttributes } from '~/composables/pdp/pdpTypes'

const props = defineProps({
  basketItem: {
    type: Object as PropType<FimBasketItem>,
    required: true,
  },
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  variant: {
    type: Object as PropType<Variant>,
    required: true,
  },
  name: {
    type: String as PropType<string>,
    required: true,
  },
  title: {
    type: String as PropType<string>,
    required: true,
  },
  price: {
    type: Object as PropType<BapiPrice>,
    required: true,
    default: () => {},
  },
  image: {
    type: Object as PropType<ProductImageType>,
    required: false,
    default: () => undefined,
  },
  quantity: {
    type: Number as PropType<number>,
    default: 1,
  },
  isLightVariant: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  displayData: {
    type: Object as PropType<object>,
    default: undefined,
  },
  customData: {
    type: Object as PropType<object>,
    default: undefined,
  },
})

defineEmits(['click:delete'])

const localePath = useLocalePath()
const { getProductDetailRoute } = useRouteHelpers()
const { trackSelectItem } = await useTrackingEvents()

const basketItem = computed(() => props.basketItem)

const { isFreeGift, promotionStyle } = await useBasketItemPromotion(basketItem)

const isContactLensProduct = isContactLens(props.product)
const isRx = isCorrectionalGlasses(props.product)

const allowedDisplayData = computed(() => {
  // displayData should be intact since it's used by in the checkout process
  const displayDataCopy: any = structuredClone(toRaw(props.displayData)) || {}

  // Don't show Rx Prescription values
  if (isRx) {
    for (const attr of [
      RxDisplayDataAttributes.prescriptionValuesLeftAttribute,
      RxDisplayDataAttributes.prescriptionValuesRightAttribute,
      RxDisplayDataAttributes.sizeAttribute,
    ]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete displayDataCopy[attr]
    }
  }

  for (const attr of [
    DisplayDataAttributes.deliveryForecastAttribute,
    DisplayDataAttributes.sizeAttribute,
  ]) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete displayDataCopy[attr]
  }

  return displayDataCopy
})

const contactLensesPackaging = computed(() => {
  return (
    props.displayData &&
    (Object.values(props.displayData).find((i) => i.key === 'numberOfLenses')
      ?.value ||
      0)
  )
})

const lensesDetails = computed(() => {
  if (isContactLensProduct && props?.customData) {
    return getLensDetailsFromBasketItem(props.customData, props.variant)
  }

  return undefined
})

const appliedReductions = computed(() =>
  getAppliedReductionsByCategory(props.price, 'sale'),
)
const withLabel = ['packingTypeDescription']
</script>
