<template>
  <div class="w-full lg:relative">
    <!-- MOBILE ONLY Search Button -->
    <div class="md:hidden">
      <FimButton
        v-if="!showSearchBarMobile"
        key="search-btn"
        class="h-[42px] border-transparent"
        type="tertiary"
        :aria-label="$t('search.label')"
        no-padding
        @click="openSearchBarForMobile"
      >
        <LazyIconFielmannSearch class="size-7" />
      </FimButton>
      <FimButton
        v-if="showSearchBarMobile"
        key="close-btn"
        class="h-[42px] border-none"
        type="tertiary"
        :aria-label="$t('search.close_label')"
        no-padding
        @click="close"
      >
        <LazyIconFielmannClose class="size-7" />
      </FimButton>
    </div>
    <div class="z-30 w-full">
      <div
        class="container-search-mobile absolute left-0 z-30 w-full border border-x-0 border-gray-300 bg-white px-6 py-2 before:border-l before:border-t before:border-gray-300 md:relative md:top-0 md:block md:border-transparent md:bg-transparent md:p-0 header:py-2"
        :class="{ hidden: !showSearchBarMobile }"
      >
        <div class="relative rounded-md border border-gray-300">
          <div class="absolute right-0 top-0 h-full">
            <div
              class="flex h-full items-center last:rounded-l-none last:border-0"
            >
              <FadeInTransition>
                <FimButton
                  v-if="searchQuery.length > 0"
                  type="link"
                  class="h-full w-10"
                  :aria-label="$t('search.reset')"
                  data-testid="header_search_button_reset"
                  no-padding
                  @click="resetSearch"
                >
                  <template #icon>
                    <LazyIconFielmannClose class="size-5" />
                  </template>
                </FimButton>
              </FadeInTransition>
              <FimButton
                type="link"
                :aria-label="$t('search.submit')"
                class="h-full w-10 disabled:!opacity-100"
                :disabled="searchQuery === ''"
                data-testid="header_search_button_submit"
                no-padding
                @click="onSearchInputSubmit"
              >
                <template #icon>
                  <LazyIconFielmannSearch class="size-6" />
                </template>
              </FimButton>
              <button
                v-if="isSyteEnabled"
                href="javascript:void(0)"
                class="--syte-start-camera-upload h-full w-10 text-center"
                data-camera-button-placement="search-bar"
                data-tracking-label="Image Search | search-bar"
                :aria-label="$t('search.syte_button')"
                @click="trackClick"
              >
                <LazyIconFielmannCameraBordered
                  class="pointer-events-none inline-block size-6"
                />
              </button>
            </div>
          </div>
          <!-- without the @input the searchQuery is not updated on mobile phones -->

          <input
            id="search"
            ref="search-input"
            v-model="searchQuery"
            name="term"
            data-testid="header_search_input"
            :placeholder="$t('search.placeholder')"
            autocomplete="off"
            class="w-full truncate rounded-md border border-transparent bg-white py-1.5 pl-3 pr-20 outline-none focus:border-black focus:ring-0"
            type="text"
            @focus="onInputFocus"
            @blur="onInputBlur"
            @keydown.enter="onSearchInputSubmit"
          />
        </div>
      </div>
    </div>

    <Flyout :is-open="isFocused">
      <!-- The height needs to be an absolute value (or relative with vh/vw) to be scrollable on mobile phones. VH considers the actual height even with the mobile keyboard open. 126px is 2 times the header height on mobile -->
      <LazySearchResults
        v-if="showSuggestions"
        class="h-[calc(100vh-126px)] overflow-auto md:max-h-[600px]"
        :brands="brands"
        :categories="categories"
        :fetching="fetching"
        :product-suggestions="products"
        :results-count="totalCount"
        :search-term="searchQuery"
        :status="status"
        @on-flyout-dismiss="close"
        @click:result="trackFimSearchResult"
      />
      <LazySearchResults
        v-else
        class="h-[calc(100vh-126px)] overflow-auto md:max-h-[600px]"
        :categories="fallbackCategorySuggestions"
        :fetching="false"
        :product-suggestions="fallbackProductSuggestions"
        :results-count="0"
        :search-term="''"
        :status="'success'"
        @on-flyout-dismiss="close"
        @click:result="trackFimSearchResult"
      />
    </Flyout>
  </div>
</template>

<script setup lang="ts">
import type { Product } from '@scayle/storefront-core'
import { ProductStandardSorting, SortOrder } from '@scayle/storefront-api'
import type { TypeaheadSuggestion } from '@scayle/storefront-nuxt'
import { buildSearchData } from '~/utils/tracking'
const scope = effectScope()
onUnmounted(() => scope.stop())
const searchTrackingData = ref<SearchResultData>()

const {
  debouncedTypeaheadSearch,
  fetching,
  searchQuery,
  resetSearch,
  typeaheadStatus: status,
  typeaheadProducts: products,
  typeaheadBrands: brands,
  typeaheadCategories: categories,
  typeaheadTotalCount: totalCount,
  resolveSearchAndRedirect,
} = useSearchData()

const { isSyteEnabled } = useFeatureToggles()
const {
  trackButtonClick,
  trackSearchResultClick,
  trackSearchResultView,
  trackSelectItem,
  trackViewItemList,
} = await useTrackingEvents()

const isMobile = () => useViewport().isLessThan('md')

const showSuggestions = computed(() => {
  if (searchQuery.value.length > 2) {
    return true
  }
  return totalCount.value > 0
})

const showSearchBarMobile = ref(false)
const searchInput = useTemplateRef<HTMLInputElement>('search-input')

// Fallback content
const fallbackProducts = useProducts({
  params: () => ({
    sort: {
      sortingKey: 'search-fly-out',
      direction: SortOrder.Ascending,
    },
    category: '/',
    perPage: 6,
    with: SAFE_PRODUCT_WITH,
    includeSellableForFree: true,
    cache: {
      ttl: getTtl(),
      cacheKeyPrefix: `SEARCH:FALLBACK`,
    },
  }),
  key: `top-6-newest-products`,
  options: {
    dedupe: 'defer',
    immediate: true,
  },
})

const fallbackCategoryList = useCategories({
  params: {
    path: '/',
    children: 0,
  },
})

const fallbackProductSuggestions = computed(() =>
  fallbackProducts.data.value?.products.map((product) => ({
    type: 'product',
    score: 1,
    productSuggestion: {
      suggestion: getProductName(product),
      product: product,
    },
  })),
)

const fallbackCategorySuggestions = computed(() => {
  const value = fallbackCategoryList.data.value?.categories
  return (Array.isArray(value) ? value : [value])
    .filter(Boolean)
    .slice(0, 3)
    .map((category) => ({
      type: 'brandOrCategory',
      score: 1,
      brandOrCategorySuggestion: {
        suggestion: category.name,
        primaryMatch: 'category',
        category,

        /**
         * The total number of products matching the suggested filters
         */
        productCount: 0,
      },
    }))
})

const openSearchBarForMobile = () => {
  showSearchBarMobile.value = true

  nextTick(function () {
    searchInput.value?.focus()
  })
}

const onInputFocus = () => {
  isFocused.value = true
  // Solves https://aboutyou.atlassian.net/browse/SCFIM-1538
  document.querySelector('html')?.setAttribute('data-is-search-focused', '')
}

const trackSearchView = () => {
  if (!searchTrackingData?.value?.search?.search_term?.trim()) {
    return
  }

  trackSearchResultView(searchTrackingData.value)
  trackViewItemList(
    [],
    {
      id: CategoryListingMetadata.ID,
      name: CategoryListingMetadata.NAME,
    },
    undefined,
    undefined,
    searchTrackingData.value,
  )
}

const onInputBlur = () => {
  trackSearchView()

  if (isMobile()) {
    return
  }
  isFocused.value = false
  document.querySelector('html')?.removeAttribute('data-is-search-focused')
}

const onSearchInputSubmit = async () => {
  await resolveSearchAndRedirect()
  close()
}

const trackClick = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)
}

scope.run(() => {
  if (import.meta.server) {
    return
  }

  watch(
    () => searchQuery.value,
    (query) => {
      if (!query) {
        return resetSearch()
      }
      isFocused.value = true
      debouncedTypeaheadSearch()
    },
  )

  // used to store the Search Tracking data
  watch(products, () => {
    searchTrackingData.value = buildSearchData(
      searchQuery.value,
      products.value.length,
      categories.value.length,
      1,
      'search_results_flyout',
    )
  })
})

const close = () => {
  searchInput.value?.blur()
  searchQuery.value = ''
  showSearchBarMobile.value = false
  isFocused.value = false
  resetSearch()
}

const trackFimSearchResult = (item: TypeaheadSuggestion) => {
  const element = {} as {
    label: string
    id: string
  }

  let product

  if ('productSuggestion' in item) {
    element.id = item.productSuggestion.suggestion
    element.label = item.type
    product = item.productSuggestion.product as Product
  } else {
    element.id = item.brandOrCategorySuggestion.suggestion
    element.label = 'category'
  }

  const searchData = buildSearchData(
    searchQuery.value,
    products.value.length,
    categories.value.length,
    1,
    'search_results_flyout',
    true,
  )
  trackSearchResultClick(searchData, element)

  if (product) {
    trackSelectItem(
      product,
      {
        categoryId: CategoryListingMetadata.ID,
        categoryName: CategoryListingMetadata.NAME,
      },
      undefined,
      undefined,
      undefined,
      searchData,
    )
  }

  close()
}

const isFocused = ref(false)
</script>

<style lang="scss">
.disabled-link {
  pointer-events: none;
}

.container-search-mobile::before {
  width: 0.75rem;
  height: 0.75rem;
  top: -0.45rem;
  right: 9.9rem;
  position: absolute;
  transform: rotate(45deg);
  background-color: #ffffff;

  @media screen and (width < 768px) {
    content: '';
  }

  @media screen and (width >= 375px) {
    right: 13.5rem;
  }

  @media screen and (width >= 768px) {
    display: none;
  }
}
</style>
