import type {
  Product,
  Variant,
  BasketItem,
  WishlistItem,
  ShopUser,
  Price as BapiPrice,
} from '@scayle/storefront-nuxt'
import type { VariantDetail } from '@scayle/storefront-api'
import type { NuxtApp } from 'nuxt/app'
import type { ConfiguratorState } from '~/composables/useRxConfiguratorState'
import type { IRxStepNameValue } from '~/components/rx/RxConfigurator/RxConfigurator'

// Constants
export const ECOMMERCE_TRACKING_EVENTS = [
  'FielmannBasic_EC_AddToCart',
  'FielmannBasic_EC_AddToWishlist',
  'FielmannBasic_EC_ItemView',
  'FielmannBasic_EC_ItemViewList',
  'FielmannBasic_EC_Purchase',
  'FielmannBasic_EC_RemoveFromCart',
  'FielmannBasic_EC_RemoveFromWishlist',
  'FielmannBasic_EC_SelectItem',
  'FielmannBasic_EC_PromotionClick',
  'FielmannBasic_EC_PromotionView',
] as const

export type BasicTrackingEvent =
  | 'shop_init'
  | 'shop_change'
  | 'customer_data'
  | 'content_view'
  | 'FielmannBasic_MenuItemClick'
  | 'FielmannBasic_MenuIconClick'
  | 'FielmannBasic_FooterItemClick'
  | 'FielmannBasic_FooterIconClick'
  | 'FielmannBasic_VirtualPageview'
  | 'FielmannBasic_TextLinkClick'
  | 'FielmannBasic_EC_CartView'

export type CheckoutTrackingEvent =
  | 'FielmannBasic_EC_BeginCheckout'
  | 'finish_checkout'

export const ADDITIONAL_TRACKING_EVENTS = [
  'FielmannBasic_404Error',
  'wishlist',
  'search',
  'filter',
  'login',
  'logout',
  'sign_up',
  'newsletter',
] as const

export const PROMOTION_TRACKING_EVENTS = [
  'FielmannBasic_EC_PromotionView',
  'FielmannBasic_EC_PromotionClick',
] as const

export const MULTIPLE_PROMOTION_EVENTS = [
  'FielmannBasic_EC_CartView',
  'FielmannBasic_EC_BeginCheckout',
  'FielmannBasic_EC_Purchase',
] as const

export const LIST_ATTRIBUTION_EVENTS = [
  'FielmannBasic_EC_ItemView',
  'FielmannBasic_EC_AddToCart',
  'FielmannBasic_EC_BeginCheckout',
  'FielmannBasic_EC_CartView',
  'FielmannBasic_EC_Purchase',
] as const

// Events
declare global {
  type EcommerceTrackingEvent = (typeof ECOMMERCE_TRACKING_EVENTS)[number]
  type AdditionalTrackingEvent = (typeof ADDITIONAL_TRACKING_EVENTS)[number]
  type PromotionTrackingEvent = (typeof PROMOTION_TRACKING_EVENTS)[number]
  type MuliplePromotionTrackingEvent =
    (typeof MULTIPLE_PROMOTION_EVENTS)[number]
  type ListAttributionTrackingEvent = (typeof LIST_ATTRIBUTION_EVENTS)[number]
  type VTOTrackingEvent = 'FielmannBasic_VirtualTryOn'
  type LeadFormSubmitEvent = 'FielmannBasic_LeadFormSubmit'
  type CTATrackingEvent = 'FielmannBasic_CTAClick'
  type NotificationTrackingEvent = 'FielmannBasic_ApplicationNotification'
  type ButtonClickEvent = 'FielmannBasic_ButtonClick'
  type RxConfiguratorEvent = 'FielmannBasic_Configurator'
  type PdScanEvent = 'PDScan_VirtualPageview'
  type SearchResultViewEvent = 'FielmannBasic_SearchResultView'
  type SearchResultClickEvent = 'FielmannBasic_SearchResultClick'

  type TrackingEvent =
    | EcommerceTrackingEvent
    | BasicTrackingEvent
    | AdditionalTrackingEvent
    | CheckoutTrackingEvent

  type AuthTrackingEvent =
    | 'login'
    | 'logout'
    | 'sign_up'
    | 'forgot_password'
    | 'reset_password'
    | 'guest_login'

  type FimTrackingEvent =
    | TrackingEvent
    | VTOTrackingEvent
    | LeadFormSubmitEvent
    | CTATrackingEvent
    | NotificationTrackingEvent
    | ButtonClickEvent
    | RxConfiguratorEvent
    | PdScanEvent
    | SearchResultViewEvent
    | SearchResultClickEvent
}

// Payloads
declare global {
  interface MapToTrackingPayloadOptions {
    isBotTraffic?: boolean
    variant?: Variant | VariantDetail | null
    user?: ShopUser
    context?: any
    isLoggedIn?: boolean
    overridePageType?: PageType
    overridePrice?:
      | BapiPrice
      | ((
          product: Product,
          variant?: Variant | VariantDetail | null,
        ) => BapiPrice | undefined)
    $currentShop?: NuxtApp['$currentShop']
    $i18n?: NuxtApp['$i18n']
    pageState?: any
    route?: any
  }

  interface FimButtonTrackingPayload {
    element:
      | HTMLButtonElement
      | HTMLAnchorElement
      | { id: string; label: string }
  }

  interface FimLinkTrackingPayload {
    element: HTMLAnchorElement
  }

  interface FimVtoTrackingPayload extends FimButtonTrackingPayload {
    productDetails: Product
  }

  interface FimCTATrackingPayload extends FimButtonTrackingPayload {
    productDetails?: Product | null
  }

  interface FimLeadFormSubmitTrackingPayload {
    user: {
      email: string
    }
    form: {
      identifier: string
    }
  }

  interface NotificationTrackingPayload {
    error: {
      notification: string
    }
    meta?: {
      applicationId: string
    }
  }

  interface FimRxConfiguratorPayload {
    productDetails: Product
    configurator: {
      id: 'RX Configurator' | 'Prescription Configuration'
      step: number
      stepName: IRxStepNameValue | IPrescriptionProp
    }
    configuration?: ConfiguratorState
  }

  type PdTrackingPayload = {
    [key: string]: any
  }

  interface FimSearchPayload extends FimButtonTrackingPayload {
    search: SearchResultData
  }

  type FimTrackingPayload =
    | FimVtoTrackingPayload
    | FimCTATrackingPayload
    | FimLeadFormSubmitTrackingPayload
    | NotificationTrackingPayload
    | FimButtonTrackingPayload
    | FimLinkTrackingPayload
    | FimRxConfiguratorPayload
    | PdTrackingPayload
    | FimSearchPayload

  type TrackingPayload =
    | BasketData
    | CustomerData
    | CustomerInfo
    | CustomerLogoutData
    | EcommerceData
    | FilterData
    | MultipleActionData
    | PageViewData
    | ProductActionData
    | ProductListData
    | ProductViewData
    | PromotionData
    | SearchData
    | ShopData
    | ShopInitData
    | WishlistData
    | CartViewPayload
}
declare global {
  type PageType =
    | 'access'
    | 'account_area:OId'
    | 'account_area:userIndex'
    | 'account_area'
    | 'adp'
    | 'basket'
    | 'brand_overview'
    | 'brand'
    | 'browse'
    | 'category_overview'
    | 'category'
    | 'checkout:#/auth'
    | 'checkout:#/payment'
    | 'checkout:#/shipping'
    | 'checkout'
    | 'discovery'
    | 'error'
    | 'error'
    | 'FielmannBasic_EC_CartView'
    | 'help'
    | 'homepage'
    | 'lookbooks'
    | 'navigation'
    | 'off_canvas'
    | 'offCanvas'
    | 'osp'
    | 'other'
    | 'outfit_overview'
    | 'outfit'
    | 'pdp'
    | 'search result page'
    | 'search_results_flyout'
    | 'search_results_page'
    | 'searchresults'
    | 'service_pages'
    | 'social_media'
    | 'story_overview'
    | 'story'
    | 'wishlist'
    | PlpPageType
    | string

  interface TrackFunction {
    (
      event: TrackingEvent,
      payload: TrackingPayload,
      options?: MapToTrackingPayloadOptions,
    ): any
  }

  interface FimMetaTrackingData {
    applicationVersion: string
    applicationId: string
    pageType: string
    country: string
    language: string
    virtualPagePath: string
    virtualPageTitle: string
    ep_bot_traffic: boolean
  }

  interface FimUserTrackingData {
    loginStatus: string
    loginType: string
    email: string
  }

  interface FimProductTrackingData {
    name: string
    id: string
    sku: string
    brand: string
    category: string

    category_2: string

    category_3: string

    category_4: string

    category_5: string
    price: string
    variant: string
    currency: string
    availability: {
      deliveryDays: string
      deliveryText: string
    }
  }

  interface VtoTrackingData {
    event: string
    user: FimUserTrackingData
    meta: FimMetaTrackingData
    element?: {
      label: string
      id: string
    }
    productDetails?: FimProductTrackingData
  }

  interface FimRxConfigurationMapped {
    configuration: {
      prescriptionType?: 'Prescription Glasses' | 'Non Prescription Glasses'
      prescriptionValues?: 'Given' | 'Not Given'
      glassType?:
        | string
        | 'Basic'
        | 'Basic Plus'
        | 'Smart'
        | 'Select'
        | 'Excellence'
      extras?:
        | 'Plain'
        | 'Blue Blocking'
        | 'Tinted | Brown'
        | 'Tinted | Grey'
        | 'Tinted | Green'
    }
  }

  interface BasicViewData {
    content_name: string
    page_type: PageType
    page_type_id: string
  }

  interface TrackingCategory {
    name: string
    id: string
  }

  interface ListItem {
    name: string
    id: number | string
    index?: number
  }

  interface SearchResultData {
    search: {
      search_term: string
      search_results_products: any
      search_results_categories: any
      search_results_pages: any
      search_count: number
      search_destination?: string
      search_location: PageType
    }
  }

  interface ProductListData {
    product: Product
    variant?: Variant
    category?: TrackingCategory
    list?: ListItem
    quantity?: number
    pagePayload?: BasicViewData
  }

  interface ProductViewData extends ProductListData {
    position?: number
    pagePayload?: BasicViewData
    search?: SearchResultData
  }

  interface MultipleActionData {
    currencyCode?: string
    valueWithoutTax?: number
    items: ProductListData[]
    pagePayload?: BasicViewData
    search?: SearchResultData
  }

  interface ProductActionData {
    product: Product & { index?: number }
    variant?: Variant
    category?: TrackingCategory
    quantity?: number
    list?: ListItem
    currencyCode?: string
    index?: number
    pagePayload?: BasicViewData
  }

  interface ProductInfo {
    item_id: string
    item_name: string
    price_gross: number
    price: number
    sale_discount: number
    campaign_discount: number
    original_price: number
    item_brand: string
    item_brand_id: string
    tax?: number
  }

  interface AdditionalInfo {
    item_category: string
    item_category_id: string
    item_variant: string
    quantity?: number | undefined
    item_list_name?: string
    item_list_id?: string
    index?: number
  }

  interface ViewInfo extends AdditionalInfo {
    sold_out?: boolean
    bi_price: string
  }

  interface ShopData {
    shop_id: string | number
    shop_gender: string
    locale: string
    shop_currency: string
  }

  interface FilterData {
    action?: string
    label?: string
    sortType?: string
    filter_name: string
    filter_values: string
  }

  interface BasketData {
    items: BasketItem[]
    total_campaign_reduction_with_tax: number
    total_sale_reduction_with_tax: number
    total_with_tax: number
    total_without_tax: number
  }
  interface WishlistData {
    items: WishlistItem[]
    total_campaign_reduction_with_tax: number
    total_sale_reduction_with_tax: number
    total_with_tax: number
    total_without_tax: number
  }

  interface CartViewPayload extends BasketData {
    product: Product
    price: any
    customData: FimBasketItemCustomData
  }

  interface PromotionData {
    promotion_id: string
    promotion_name: string
    creative_name: string
    creative_slot: string
    index?: number
  }

  interface ListingData {
    item_list_name?: string
    item_list_id?: string
  }

  interface ShopInitData extends ShopData {
    landing_page: string
    shop_version: string
    parameter: string
    referrer: string
    deeplink: string
    origin: string
  }

  interface PageViewData extends BasicViewData {
    title: string
    click_origin?: string
    error?: {
      referringURL?: string
      errorURL: string
    }
    meta?: {
      applicationId: string
    }
  }

  type SearchAction =
    | 'history_term'
    | 'suggested_product'
    | 'suggested_brand'
    | 'suggested_category'
    | 'search_button'
    | 'search_hotkey'

  interface SearchData {
    search_term: string
    search_term_completed: string
    search_action: SearchAction
    search_destination: string
  }

  type AuthenticationType = 'none' | 'email' | 'facebook' | 'apple' | 'password'

  type CustomerType = 'guest' | 'new' | 'existing'

  type StatusType = 'successful' | 'error'

  type EventType = 'login' | 'login_modal' | 'sign_up'

  interface CustomerData {
    customer_id?: number
    customer_type?: CustomerType
    login?: boolean
    method: AuthenticationType
    bi_vp?: boolean
    bi_sc?: number
    eh: string
    status?: StatusType
  }

  interface CustomerInfo {
    customer_id?: number
    customer_type: CustomerType
    method: AuthenticationType
    eh: string
    status?: StatusType
  }

  interface CustomerLogoutData {
    customer_id?: number
    eh: string
  }

  interface EcommerceItem {
    key?: string
    item_id: string
    item_sku?: string
    item_name: string
    affiliation?: string
    coupon?: string
    discount?: number
    item_brand: string
    item_brand_id: string
    item_category?: string
    item_category2?: string
    item_category3?: string
    item_category4?: string
    item_category5?: string
    item_list_id?: string
    item_list_name?: string
    item_variant: string
    price: number
    quantity: number
    index: number
    price_gross?: number
    sale_discount?: number
    campaign_discount?: number
    original_price?: number
    item_category_id?: string
  }

  interface EcommerceData {
    ecommerce: {
      transaction_id: string
      orderTimestamp?: string
      customer_id: string
      payment: string
      affiliation?: string
      value: number
      value_gross: number
      tax: number
      shipping: number
      currency: string
      coupon?: string
      items: EcommerceItem[]
    }
    user: {
      email: string
      loginStatus?: string
      loginType?: string
    }
  }

  interface AdditionalItem extends AdditionalInfo {
    item_id: string
    item_name: string
    price: number
    sale_discount: number
    campaign_discount: number
    original_price: number
    item_brand: string
    item_brand_id: string
  }

  type TrackAddToBasketParams = {
    product: Product
    quantity?: number
    variant?: Variant
    index?: number
    list?: ListItem
  }

  type TrackCustomerDataParams = {
    user?: ShopUser
    customerType: CustomerType
    isLoggedIn: boolean
    biVp?: boolean
    biSc?: number
  }

  type TrackViewItemListEventParams = {
    items: any[]
    listingMetaData: ListItem
    productIndex?: number
    paginationOffset?: number
    category?: { name: string; id: string }
    positionOffset?: number
  }

  type TrackSelectItemEventParams = {
    product: Product
    category?: { name?: string; id?: number | string }
    variant?: Variant
    listingMetaData?: ListItem
    index?: number
    source?: string
    position?: number
    quantity?: number
    soldOut?: boolean
    pagePayload: BasicViewData
  }

  type TrackViewItemParams = {
    product: Product
    quantity?: number
    variant?: Variant
  }

  type TrackSearchEventParams = {
    searchTerm: string
    suggestion?: string
    searchAction:
      | 'search_button'
      | 'suggested_product'
      | 'suggested_category'
      | 'search_hotkey'
      | 'history_term'
    searchDestination?: string
  }

  type TrackContentViewEventParams = {
    clickOrigin: string
    contentName: string
    element?: any
    error?: {
      referringURL?: string
      errorURL: string
    }
    eventName: TrackingEvent
    meta?: {
      applicationId: string
    }
    pageType: PageType
    pageTypeId: string
    title: string
  }

  type EasySecondPairProgressiveGlassesConversion = {
    label: string
    id: string
  }

  type TrackAddToWishListParams = {
    product: Product
    quantity?: number
    listingMetaData?: ListItem
    category?: { id?: number; name?: string }
    variant?: Variant
    index?: number
    pagePayload: BasicViewData
  }

  type TrackRemoveFromWishListParams = TrackAddToWishListParams

  type OrderItemVariant = {
    id: number
  }
  type OrderItemProduct = {
    name: string
    variant: OrderItemVariant
    price: BapiPrice
  }
}
